/**** Responsive ****/
@media (min-width: 1200px) {
    .mobile_hmenu_list {
        display: none;
    }

    /* Mobile cms menu */
    .hmenu_list > li:hover > a > .hdd_arrow {
        opacity: 1;
        visibility: visible;
    }

    .hcategory_sec {
        display: none;
    }

    /* Mobile Header menu */
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 1.75rem auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
        min-height: -webkit-calc(100% - (1.75rem * 2));
    }
}

/* Max width */
@media (max-width: 1350px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .hmenu_list > li{margin: 0 15px 0 0;}
    ul.hmenu_list > li > a, .hsign_sec a { font-size: 13px;}
    .search-top{width: 260px;}
    .hordertype_sec a{width: auto;     padding: 10px 20px; font-size: 13px;}
    .featured-products-section h4, .outlet-listing h2,  .promo-home-header h4 { font-size: 25px}
}


@media (max-width: 1199px) {
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
    }

    .hmenu_actions > li.hsign_sec {
        display: none;
    }

    .hmenu_actions li.horder_btn {
        display: none;
    }

    .order-detail-inner-icon {
        max-width: 100%;
    }

    .order-detail-inner-icon-left.order-detail-inner-center::before {
        background-repeat: repeat;
        max-width: 100%;
        height: 6px;
    }

    .order-detail-inner {
        padding-top: 27px;
    }


    .mobile-logo-div {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        max-width: 210px;
        margin: auto;
        top: 5px;
        bottom: 5px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .mobile-logo-div a {
        display: block;
        width: 100%;
    }

    .mobile-logo-div a img {
        width: 100%
    }

    .productlist-main-div {
        width: 100%
    }
        .search-glass {
        left: 13px;
    }

    .hmenu-login-section .search-top input {
        padding-left: 27px;
    }

    .search-top {
        width: 40px;
    }
        .outlet-listing-rhs > ul > li {
        width: 32%; margin: 0 2% 20px 0;
    }
    .outlet-listing-rhs > ul > li:nth-child(4n), .search-resturent > ul > li:nth-child(4n) {  margin-right: 2%;}
    .outlet-listing-rhs > ul > li:nth-child(3n), .search-resturent > ul > li:nth-child(3n) {  margin-right: 0;}
}

@media (max-width: 1080px) {
    
    .search-dishes > ul > li, .promo-group-normal .product-list ul li {
    width: 23.5%;    margin: 0 2% 32px 0;
    }
    .search-dishes > ul > li:nth-child(6n), .promo-group-normal .product-list ul li:nth-child(5n) {
    margin-right: 2%;
    }
    .search-dishes > ul > li:nth-child(4n), .promo-group-normal .product-list ul li:nth-child(4n) {
        margin-right: 0;
    }
    .search-no-parent{
        padding: 30px 0 0 0;
    }
    .order-details{
        padding: 0 100px 0 0;
    }
    
    /* .checkout-total .cdd-details-lhs {
    width: 51.09375%;
    margin-right: 7.5625%;
    }
    .checkout-total .cdd-details-rhs {
    width: 40.09375%;
    position: sticky;
    top: 110px;
} */
  .tnk-chk-rhs {
    width: 40.055749%;
    margin-left: 4.891986%;
}  
.footer-top {
    flex-wrap: wrap;
}    
.footer-newletter {
    width: 47%;
    order: 2;
}
.footer-logo {
    width: 100%;
    order: 1;
    margin-bottom: 30px;
}
.footer-nav {
    order: 3;
    width: 47%;
}    
    .mainacc_menuout{ width: 280px }    
    .mainacc_menucontent { width: calc( 100% - 300px );}
}



@media (max-width: 1024px) {
    .delivery {
        margin-top: 30px;
    }
    .congrats.redeem-promation-body {
        padding: 24px 0;
    }

    .product_chosen_hea h6 {
        font-size: 14px;
    }

    .productlist-main-div .products-list-ulmain {
        justify-content: space-between;
    }

    .productlist-main-div .products-list-ulmain > li {
        width: 48.5%;
        margin: 0 0 20px 0;
    }


    .flexway {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .flexway i {
        font-size: 22px;
        position: relative;
        top: 3px;
        transition: 0.3s linear all;
        transform: rotate(180deg);
    }

    .flexway .active i {
        transform: rotate(0);
    }

    .flexway strong {
        cursor: pointer;
    }
    .restaurant-details-rhs {
        width: 100%;
    }
    .order-details{
        padding: 0;
    }
}

@media (max-width: 991px) {
    .delivery-item span {
        font-size: 14px;
    }

    .delivery-item h2 {
        font-size: 24px;
    }

    .delivery-item p {
        font-size: 16px;
    }

    .delivery-inner-comman {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .delivery-item {
        margin-top: 10px;
    }

    .delivery_total.delivery_total_number .delivery_total_left h4 {
        font-size: 20px;
    }

    .delivery_total.delivery_total_number .delivery_total_left h2 {
        font-size: 14px;
    }
 .product-step-inner-white .delivery_total_left h2 {  font-size: 16px !important;}
    .delivery_total_left h3 {
        font-size: 15px;
    }

    .delivery_total_left span {
        line-height: normal;
    }

    .redeem-item-hea .points {
        font-size: 15px;
    }



    .innerproduct {
        margin-bottom: 25px;
    }


    .inn-product-popup-bg,
    .inn-product-popup-background {
        background-image: inherit !important;
        padding-top: 0;
        text-align: center;
    }

    .inn-product-popup-inner {
        max-width: 100%;
    }

    .other-add-row li {
        width: 100%;
    }

    .account_sec {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .accmenu_sec {
        width: 100%;
    }

    .delivery-inner {
        padding: 20px 0;
    }

    .mainacc_toptext h2 {
        margin: 10px 0 0;
        font-size: 30px
    }

    .count_num_t {
        display: none
    }



    .outlet-listing-rhs {
        padding-left: 10px;
    }
    .search-mobile-inner{
        display: block;
        margin: 20px 0 0 0
    }
    .checkout-total .cdd-details-lhs{width: 100%;margin: 0}
    .desktop-terms{display: none}
    .checkout-total .cdd-details-rhs{width: 100%;}
    .checkout-btn-total {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 13px 15px;
    box-shadow: 0px -4px 12px rgb(0 0 0 / 8%);
    z-index: 9;
}
    .mobile-terms{display: block}
    #scrollbutton{display: none !important;}
    .search-top{margin-right: 10px}
}

@media (max-width: 980px) {
 .menu_icon {
        display: block;
        width: 30px;
        height: 18px;
        margin: 0 18px 0 0;
        cursor: pointer;
    }
.hmenu_sec{ display: none;}   
 

.mobile-order-now { display: flex;  }    
  .header-address, .hordertype_sec, .hsign_sec{
        display: none;
    }    

    .logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        height: 50px;
        padding: 5px 8px;
        border-radius: 50%;
        overflow: hidden;
        background: white;
    }

    .logo a {
        display: inline-block;
        padding: 2px;
        height: 100%;
    }

    .logo img {
        height: 100%;
    }

    .menu_icon .icon-bar {
        background: white;
    }

    .mobile-cart-icon {
        display: block;
        width: 28px;
        height: 28px;
        color: white;
    }

    .htico_search {
        display: none;
    }
    .hsearch_sec_mbl {
        display: block;
    }
    .hsearch_trigger_mbl {
        display: block;
        filter: invert(1);
    }
    .hsearch_trigger_mbl .search-glass{
        width: 25px;
    }

    .cartw {
        display: none;
    }

    .header-top-cls{height: 70px; background: #576AAB}    
    .header-top-cls .container, .logo-main-section {height: 100%;}
    .bluebar-lhs, .bluebar-rhs{ display: none; }
    .bluebar-middle { width: 100%;}
    .restaurant-image-mobile {
        border-radius: 10px;
    }
    .first-list .innerproduct { margin-bottom: 0;}

    .restaurant-outlet-info {
        padding: 0;
    }

    .restaurant-outlet-slider {
        display: none;
    }

    .product-menu-listing .productlist-main-div {
        width: 100%;
    }

    .product-menu-listing .htico_cart {
        display: none;
    }

    .working-info-parent {
        background: white;
        color: black;
        padding: 0;
        display: flex;
        align-items: start;
    }

    .working-info-parent h3 {
        color: black;
        font-weight: 700;
    }

    .working-info-parent__left {
        flex-direction: column;
        width: 70%;
        font-style: italic;
        padding-right: 20px;
    }

    .working-info-parent__right {
        display: inline-block;
        width: 30%;
        color: #52525B;
    }

    .working-info-parent__right h3 {
        color: #52525B;
    }

    .mobile-nav {
        display: block;
        padding: 1px 0px 1px 70px;
        position: relative;
        width: 100%;
    }

    .mobile-nav .menu-list-intvl {
        padding: 8px 10px;
    }

    .mobile-nav .menu-list-intvl.active {
        background: #576AAB;
        padding: 8px 10px;
        border-radius: 20px;
    }
    .product-mobile-menu .mobile-nav .menu-list-intvl.active a {
        color: white;
    }

    .product-mobile-menu .mobile-nav .menu-list-intvl a {
        color: #576AAB;
    }

    .desktop-nav {
        display: none;
    }

    .view-more {
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        width: 60px;
        text-align: center;
    }

    .view-more.active {
        background: #576AAB; 
    }

    .view-more > a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 40px;
    }

    .view-more .menu_icon {
        display: inline-block;
        margin: 0;
    }

    .view-more .menu_icon .icon-bar {
        background: #576AAB;
    }

    .view-more.active .menu_icon .icon-bar {
        background: white;
    }

    .view-more__list {
        right: auto;
        left: 0;
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        min-width: 300px;
        top: 40px;
    }

    .view-more__list .menu-list-intvl {
        width: 100%;
        padding: 12px 20px;
        margin-bottom: 0;
    }

    .mobile-nav .view-more__list .menu-list-intvl.active {
        padding: 12px 20px;
    }

    .restaurant-details-rhs .menu-top-section-inner {
        padding: 0;
        border-top: 1px solid #576AAB;
        border-bottom: 1px solid #576AAB;
        margin: 20px -15px 30px;
        position: sticky;
        top: 70px;
        background: #fff;
        z-index: 9
    }

    .productlist-main-div .products-list-ulmain > li {
        flex-direction: row-reverse;
        border: 1px solid rgba(89, 108, 171, 0.5);
    }

    .products-ordernow-action {
        position: static;
        display: flex;
        align-items: center;
        padding: 8px 15px 15px;
        /* justify-content: center; */
    }

    .innerproduct > h6 {
        text-transform: capitalize;
    }

    .product-title h3 {
        text-align: left;
        text-transform: capitalize;
    }

    .products-ordernow-action .button {
        border-radius: 3px;
        width: auto;
        text-transform: none;
        max-width: unset;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.2px;
        margin-left: 15px;
    }

    .product-price {
        margin-bottom: 0;
    }

    .product-price h3 {
        font-size: 14px;
        font-weight: 600;
    }

    .product-info-div {
        padding: 0;
        height: 100%;
        background: rgba(89, 108, 171, 0.1);
    }

    .product-title-maindiv {
        flex-grow: 1; 
        padding: 16px;
    }

    .products-single-li .products-image-div {
        width: 50%;
    }

    .products-image-div {
        position: relative;
        padding-bottom: 36.6%;
        height: auto;
    }

    .products-image-div a {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .products-single-li .product-info-div {
        width: 50%;
    }

    .prd_chosen_sub_item_right button {
        border-radius: 10px;
    }

    .cart_info h4 {
        text-transform: none;
    }

    .cart-header-first .cart_left p {
        font-size: 18px;
    }

    .cart-header-first .cart_left p span {
        font-size: 15px;
    }

    .cart-header-first .cart_left h4 {
        font-size: 15px;
    }
    .cart-header-first .cart_left h4 span {
        font-size: 18px;
    }



    .cart_footer {
        background: white;
        padding-bottom: 20px;
    }
    .cart_outlet_row {
        border-bottom: 1px solid #d4d4d8;
    }

    .cart_row-full .cart_row {
        margin-bottom: 5px;
    }

    .cart_row.grant-total-cls .row-replace p {
        font-size: 18px;
    }
    .cart_row.grant-total-cls .row-replace span {
        font-size: 18px;
    }

    .chkout-main-header h2 {
        font-size: 18px;
        color: #576AAB;
        font-weight: 700;
    }

    .checkout-inner-header {
        display: none;
    }

    
    .checkout-body-section {
        border: none;
        border-bottom: 1px solid #d4d4d8;
    }
    
    .checkout-pickup-main{
        padding: 0;
    }

    .checkout-right-body-section {
        border: none;
    }

    .product-details.ckout-product-details {
        border-bottom: none;
        margin: 0;
    }

    .checkout-right-body-section .cart_table {
        padding: 0;
    }

    .chekout_cart_bar .cart-header {
        display: none;
    }

        .cart_img {
        display: none;
    }


    .sidebar-discount {
        display: none;
    }

    .checkout-right-body-main .cart_footer {
        padding: 20px 0;
    }

    .chk-payment-row {
        border: none;
    }

    .chk-payment-col {
        padding: 0;
    }

    .chk-payment-col-radio .radio_con span {
        font-size: 12px;
    }

    .chk-payment-title {
        text-align: left;
        background: white;
        padding: 30px 0 15px;
    }
    
    .chk-payment-title h3 {
        color: #576AAB;
        text-transform: none;
        text-align: left;
        font-size: 14px;
    }

    .cart_outlet_row {
        border-bottom: 1px solid #d4d4d8;
    }

    .checkout-right-body-main .cart_footer {
        background: none;
    }

    .checkout-right-body-main .progress_bar_div {
        display: none;
    }

    .cdd-details-rhs .cart_footer {
        border-top: none;
        border-bottom: 1px solid #d4d4d8;
        border-radius: 0;
    }

    .cdd-details-rhs .cart_body {
        padding: 0;
    }

    .chk-payment-btn-row .button {
        border-radius: 10px;    
    }

    .restaurant-info-lhs{  padding: 0 0 10px; }

    .restaurant-details-rhs .product-menu-listing {
        margin-top: 25px;
    }

    .tnk-chk-order a {
        border-radius: 10px;
    } 

    .back-to-menu {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 600;
    }

    .back-to-menu:hover {
        color: white;
    }

    .back-to-menu svg {
        width: 28px;
        height: 28px;
        margin-right: 8px;
    }
}

@media (max-width: 880px) {
    .restaurant-main{flex-wrap: wrap;}
    .restaurant-details-lhs{ width: 100%;}
    .restaurant-details-rhs{ width: 100%;padding: 30px 0 0 0;}
    .restaurant-details-lhs .search-product, .restaurant-details-lhs .product-menu{ display: none}
    .product-mobile-menu-nav{display: block; background: #fff;}
    .mobile-menu-nav-search{width: 19px; float: left}

    div#ProductDetailMdl{
        width: 100%;
    }    
.search-resturent > ul > li {
    width: 33.333333%;
}    
    .restaurant-info-rhs{
        display: none;
    }    
    .restaurant-details{
        padding: 0 0 20px 0;
    }
    .restaurant-image-mobile, img.restaurant-logo{display: block;}
    img.restaurant-logo{z-index: 2}
    
    .restaurant-details-rhs .product-menu-listing{margin: 25px 0 0 0}
    .restaurant-mobile-social { display: flex;}
    .restaurant-social {display: none;}
    .home-option li a{ padding: 10px 10px; font-size: 19px;}
    .home-option li:nth-child(1) a img { height: 32px; width: 33px;}
    .home-option li:nth-child(2) a img { height: 32px; width: 32px;}
    .home-option li:nth-child(3) a img{width: 32px; height: 32px}
    .home-option li a img { margin-right: 5px;}
    .menu-section-inner-scroll { display: none; }
}

@media (max-width: 820px) {
    .outlet-listing-rhs > ul{justify-content: space-between}    
.outlet-listing-rhs > ul > li {
    width: 49%;
    margin: 0 0 15px 0 !important;
}
}

@media (max-width: 768px) {
    header {
        padding: 0;
    }
    .checkout-header{
      padding: 10px 0;   
    }
      .mobile-location {
        display: block;
    }
    .mobile-location{
       display: none;   
    }
    .hmenu_actions,
    .htico_cart {
        position: static
    }

    .hcart_dropdown {
        max-width: 480px;
        width: 100%
    }

    .logo-main-section {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

   

    .header-address, .hordertype_sec, .desktop-login {
        display: none;
    }

    .header-login-area {
        display: none;
    }

    .outlet-listing-rhs {
        width: 100%;
        padding: 0;
    }

    .outlet-listing {
        padding: 0 0 30px 0;
    }

    .outlet-listing h4 {
        display: none
    }

    .mobile-outlet-filter {
        display: block;
    }
    
    .search-dishes > ul > li, .promo-group-normal .product-list ul li {
    width: 32%;    margin: 0 2% 25px 0;
    }

    .search-dishes > ul > li:nth-child(4n), .promo-group-normal .product-list ul li:nth-child(4n) {
        margin-right: 2%;
    }
        .search-dishes > ul > li:nth-child(3n), .promo-group-normal .product-list ul li:nth-child(3n) {
        margin-right: 0;
    }
    .search-resturent > ul > li {
    width: 50%;
    }  
    .mainacc_menuout{
        display: none;
    }
    .mainacc_menucontent{
        width: 100%;
        padding: 0;
    }
    .order-details {
    width: 100%;
    }
    
    .tnk-chk-rhs {  width: 100%;   margin-left:0;}  
    .tnk-chk-lhs {  width: 100%; margin: 0 0 30px 0;}  
    .cart_footer.tnk_cart_footer{ padding-top: 24px}
    /*.common-inner-banner{height: 175px;}*/
    .common-inner-banner p{font-size: 20px;}
    
    .mbacc_mslidersec{display: block;overflow: hidden; margin: 0 0 20px 0;}
    .home-banner .owl-carousel { margin-bottom: 20px;}
    .promo-home {  margin: 15px 0 35px 0;}
    .featured-products-section h4, .home-newly h4, .outlet-listing h2, .home-collaboration .container > h4, .promo-home-header h4 { font-size: 20px;}
    .home-outlet-listing ul li{width: 100%;}
    .hol-header h2{font-size: 28px;}
    .banner-info-inner h2 {  font-size: 28px;}
    .banner-info-inner p{font-size: 13px;}
 
    .productlist-main-div .products-list-ulmain > li {
        width: 100%;
        margin: 0 0 16px 0;
    }  
}


@media (max-width: 680px) {
    .prodet_bansec{width: 100%; position: relative;}
    .inn_product_row{width: 100%;padding: 0}
    .inn-product-popup{padding: 0}
    .product_chosen_item_left.product_chosen_item_left_full{ padding: 24px;    margin-bottom: 0px; }
    .product_chosen_col.product_chosen_col_right{padding: 24px;}
    .product_chosen_col_inner{margin-bottom: 0}
    .promolisting-page ul, .mypromo-main-div ul{justify-content: space-between}
    .promo-earned-col{ width: 48.5%; margin: 0 0 24px 0;}
}
@media (max-width: 620px) {
.checkout-btn-total {
    flex-flow: column wrap;align-items: stretch;
}
    .chk-payment-btn{
        width: 100%;
        padding: 0;

    }    
    
    .checkout-btn-total .checkout-total {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
        margin: 0 0 10px 0;
}
.footer-newletter {
    width: 100%;
}
.mobile-footer-nav {
    display: block;
    order: 4;
    width: 100%;
    padding-top: 20px;
}
.footer-nav {
    display: none;
}    

    .checkout-control-group-top .chk_hea {
        font-size: 14px;
    }
    .checkout-control-group-top .chk_hea span {
        font-size: 12px;
    }

    .checkout-control-group-top .chk_hea:nth-child(2) {
        font-size: 12px;
    }
    .checkout-control-group-top .chk_hea:nth-child(2) span {
        font-size: 14px;
    }

    .checkout-pickup-group-middle .timeslot_info_main .syd_date h5, 
    .checkout-pickup-group-middle .timeslot_info_main .syd_time h5 {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .checkout-pickup-group-middle .timeslot_info_main .syd_date input, 
    .checkout-pickup-group-middle .timeslot_info_main .syd_time input {
        font-size: 12px;
    }

    .ckout-product-details .cart_price p {
        font-size: 14px;
    }

    .cart_info h4 {
        font-size: 12px;
    }

    .cart_footer .cart_row .row-replace {
        font-size: 12px;
    }

    .cart_extrainfo p {
        font-size: 12px;
    }

    .cart_row.grant-total-cls .row-replace p {
        font-size: 14px;
    }
    .cart_row.grant-total-cls .row-replace span {
        font-size: 14px;
    }

    .cart-header-first .cart_left p {
        font-size: 14px;
    }

    .cart-header-first .cart_left p span {
        font-size: 12px;
    }

    .cart-header-first .cart_left h4 {
        font-size: 12px;
    }
    .cart-header-first .cart_left h4 span {
        font-size: 14px;
    }

    .tnkyou-header p {
        font-size: 14px;
    }

    .tnk-detail {
        font-size: 14px;
    }

    .tnk-detail h2 {
        font-size: 16px;
    }

    .tnk-order {
        font-size: 14px;
    }

    .tnk-order h3 {
        font-size: 24px;
        color: #576AAB;
    }

    .pickup-cart-div .pickup-thankfull h4 {
        font-size: 14px;
        margin-top: 15px;
    }

    .delivery_total_left h2 {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .delivery_total_left h4, .delivery_total_text span {
        font-size: 12px;
    }

    .pickup_total_left h2, .pickup_total_right h2 {
        text-transform: none;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .pickup_total_left h4, .pickup_total_right h4 {
        font-size: 12px;
        font-weight: 400;
    }

    .tnkyou-bd-heading h5 {
        font-size: 16px;
    }

    .tnk_cart_footer .grant-total-cls p, .tnk_cart_footer .grant-total-cls span {
        font-size: 14px;
    }


}
@media (max-width: 580px) {
    .search-dishes > ul, .promo-group-normal .product-list ul{ 
        justify-content: space-between;
    }
    .search-dishes > ul > li, .promo-group-normal .product-list ul li{
        width: 49%;
        margin: 0 0 20px 0 !important;
    }
    .search-no-parent{
        text-align: center;
    }
    .search-no-found{
        padding: 0
    }
    .search-no-found img{
        position: relative;
        margin: 0 0 30px 0;
    }
    .search-no-found h3{ 
     font-size: 23px;
        
    }
    .search-popular-cuisines ul{justify-content: center}
    .ch-links > ul > li{
        margin: 0 10px 0 0;
    }
    .products-ordernow-action .button, .pro-description { font-size: 10px;}
    .product-title h3{font-size: 16px; min-height: inherit;     margin: 0 0 10px 0;}
    .product-outlet-name{font-size: 14px;}
    .copyright-section { padding:0px 0 10px 0;}
    footer { padding: 30px 0 0 0;}
    .home-newly { padding: 10px 0 30px;}
    .featured-products-main .owl-carousel .owl-stage-outer, .products-list-ulmain .owl-carousel .owl-stage-outer { padding: 5px 0 25px 0;}
    .social-media a img { height: 28px; width: 28px;}
    .footer-newletter h4 { font-size: 18px; line-height: 1.2;}
    .filter_tabsec .nav-tabs>li{     border-bottom: 2px solid #f0f0f0; }
    .filter_tabsec .nav-tabs>li>a:after{ height: 2px;     bottom: -2px;}
    .promo-form h3{font-size: 20px}
    .filter_tabsec .nav-tabs > li > a{font-size: 13px;}
    .main_tabsec{margin: 0 0 10px 0;}
    .accsec_right .welcome-title h1{margin-bottom: 15px; font-size: 20px}
    .home-option li a { padding: 6px 10px; font-size: 14px;}
    .home-option li:nth-child(1) a img {
    height: 23px;
    width: 24px;
}
.home-option li:nth-child(2) a img {
    height: 24px;
    width: 24px;
}
.home-option li:nth-child(3) a img {
    width: 24px;
    height: 24px;
}    
    .hmenu-login-section .search-top input{ border: 0;  }    
    .search-glass { left: 8px; width: 20px; }
    .search-top { width: 30px; margin-right:0 }
    .float-logo img { width: 100px;}
a.float-logo {  margin-top: 0px; margin-bottom: 15px;}
.footer-newletter h5, .company-info h5 { margin-bottom: 10px;}    
    .footer-top{padding-top: 0}    
    .footer-logo{margin-bottom: 20px}
    footer { padding: 15px 0 0 0;}
    .product-short-description p{font-size: 13px; line-height: 16px;}
    /* .restaurant-image-mobile{  border-radius: 30px 30px 0 0;    margin: 0; } */
    /* .working-info-parent{border-radius: 0 0 30px 30px;} */
    .innerproduct > h6 { margin-bottom: 15px}
    .full-login-new-header h3{font-size: 28px;}
    .full-login-new-header p{font-size: 13px;}

    .redeem {
        padding: 17px 25px 17px 25px;
    }
    .order-popup-header h3{ font-size: 26px;}
    .order_delivery_item li a { padding: 15px 5px; font-size: 16px;}
    .order_delivery_item li .header-ordernow-single-img img {  height: 44px;}
    .order_delivery_item li { width: 32%;}
    .home-banner .owl-carousel .owl-item > div { padding: 40px 0;  position: relative;}
.home-banner .owl-carousel .owl-item > div img {position: absolute; left: 0; top: 0; width: 100%; height: 100%; object-fit: cover; object-position: left;}
.banner-info {
    position: relative;
    top: inherit;
    transform: none;
    padding: 0 15px; z-index: 1
}
.home-banner .owl-dots {
    bottom: 15px;
}
.banner-info-inner .button {
    font-size: 13px;
    margin: 15px 0px 0px 0px;
    padding: 10px 15px;
}    
.home-outlet-listing { padding: 30px 0 100px 0; background: #eaf0f9;}
    .redeem-item-top a{position: static;}    
.redeem-item-hea {
    width: calc( 100% - 130px );
}
.redeem_apply.applypromo_cls {
    width: 130px;
}
.applypromo_cls .applyPromo {
    padding: 13px 10px;
    font-size: 14px;
}    
    .redeem-item-top h4{font-size: 22px;}    

    .checkout-control-group-top .chk_hea {
        width: 30%;
    }
    .checkout-control-group-top .chk_hea:nth-child(2) {
        text-align: left;
    }

    .delivery_info_tmain .syd_merge .syd_time .d_timediv, .d_timediv input {
        text-align: left;
    }

    .tnkyou-header img {
        display: none;
    }

    .tnkyou-header h2 {
        font-size: 18px;
    }

    .tnkyou-innerdiv {
        border: 0;
    }

    .thank-order-detaildiv {
        background: white;
    }

.checkout-pickup-group-middle .timeslot_info_main .syd_date input,
.checkout-pickup-group-middle .timeslot_info_main .syd_time input{
   text-align: left; 
}

.checkout-pickup-group-middle .timeslot_info_main .syd_date h5,
.checkout-pickup-group-middle .timeslot_info_main .syd_time h5{
    text-align: left;
}
.checkout-pickup-group-middle .timeslot_info_main .syd_time{
    width: 30%;
}


    .tnk_cart_footer .grant-total-cls {
        padding-bottom: 20px;
        border-bottom: 1px solid #d4d4d8;
    }
}

@media (max-width: 480px) {
    .container {
    padding-left: 15px;
    padding-right: 15px;
}
    .banner-count-parent,
    .home-banner .owl-nav,
    .promo-banner .owl-nav,
    .featured-products-main .owl-nav,
    .cart-outer-info p,
    .home-newly .owl-nav{
        display: none;
    }
    .hmenu-login-section .search-top input{
        display: none;
    }
    .home-collaboration .featuredpro-section .container { padding: 15px 0 0 0;}
.collaboration-logo ul li a {  width: 55px; height: 55px;}
.collaboration-logo ul li {
    margin: 0 3px 5px 3px;
}
    .ob-content h5 {
    font-size: 16px;
    margin: 0 0 10px 0;
}
    .ob-content span{
        font-size: 16px;
    }

    .cartp {
        display: block;
        width: 18px;
    }

    /* .cart-outer {
        border-radius: 0;
        background: none;
        width: 20px;
        height: 20px;
        order: 1;
    } */
   .htico_cart {
       margin-left: 10px;
   }
  .cart-outer-info {
    order: 2;
    margin: 0 0px 0 5px;
}
   .search-top {
       width: 20px;
       margin-right: 0;
       height: 19px;
   }

    .outlet-listing-rhs > ul > li,
    .search-resturent > ul > li {
        width: 100%;
        margin: 0 0 15px 0;
    }

    .home-collaboration .container > h6 {
        font-size: 18px;
        letter-spacing: 0.06em;
    }

    .home-collaboration .container > h5 {
        font-size: 24px;
        letter-spacing: 0.006em;
    }

    .featuredpro-section h4, .promo-home-header h4, .home-newly h4, .mobile-outlet-filter h4, .home-collaboration .container > h4{ 
        font-size: 18px;
    
    }
    .opened-carsouel{
        padding: 0
    }
    .opened-carsouel .owl-stage{
        padding: 0
    }
    .mobile-outlet-filter h4{
        text-transform: uppercase
    }
    .promo-home {
    margin: 28px 0 38px 0;
}
    .promo-home-header a{
        display: inline-flex
        
    }
    .filter-option h5{
        margin-bottom: 25px;
    }

    .innerproduct > h3{
        font-size: 18px;
    }
    .category-product-list {
    margin: 0 0 30px 0;
    }
    
    .working-hr{ margin: 0 0 16px 0; }
    .promo-earned-col{
        
        width: 100%;
    }
    .bigweight{
        font-size: 18px;
    }
    .search-result {
    padding: 30px 0 20px;
    }
        .search-resturent > ul > li {
    width: 100%;
}  
    .current_order .myacc_order_details ul li{
        flex-wrap: wrap;
    }
    .order-lhs{width: 100%}
    .order-rhs{width: 100%;padding:18px 0 0 34px; justify-content: flex-start;}
    .innersection_wrap {
    padding: 20px 0 0 0;
}
    /* .tnk-order h3{
        color: #fff;
    } */
    .order-date-parent{
        flex-wrap: wrap;
        padding: 30px 15px;
        border-bottom: 8px solid #e5e5e5
    }
    .order-date-parent > div{
        width: 100%;
    }
.myacc-thkyou-main .order-date-parent {
    padding: 20px 20px;
}
    .myacc-thkyou-main .tnk-header {
    padding: 15px 20px;
}
    /* .delivery_total_left h4, .delivery_total_text span{
        font-size: 15px;
    } */
    .delivery_total_number{
     margin: 0 0 20px 0;   
    }
    .orderitem_body_div{
        border: 0;
        padding:25px 15px 20px 15px;
    }
    .myadmin_wrap .cart_footer.tnk_cart_footer{
        padding: 25px 15px;
        border-bottom: 8px solid #e5e5e5;
        border-top: 8px solid #e5e5e5
    }
   .myadmin_wrap .cart_footer.tnk_cart_footer  .changi-reward{ margin-bottom: 10px}
    .order-footer-add-info{ max-width: 100%; padding: 25px 15px 0 15px;}
    .checkout-control-group-top .form-group, .checkout-billing-address .form-group{
        max-width: 100%;
    }
    .cdd-details{ 
     margin-bottom: 120px;
    }
    .chk-payment {
    border-radius: 0;
    padding: 10px 0px 0px 0px;
}
    .cart-view-cart{display: none;}
    .mobile-terms{margin: 0}
    .cdd-details-rhs .cart_footer{padding-bottom: 0; border-top: 0;}
    .chk-payment{ padding-bottom: 20px; border: 0}
    .htico_cart > a strong{display: none}
    .ch-links ul .htico_cart .hcartdd_trigger div img{margin-right: 0}
    .thy{padding-top: 0px}
    .mainacc_toptext.tick h4, .tnk-chk-rhs, .thank-product-img{display: none}
    .mobile-payment-summary{display: block}
    .thankyou-footer{text-align: center; margin: 0 0 20px 0;}
   .hcart_tt_mobile {
    display: flex;
    position: relative;
    border-top: 1px solid #e5e5e5;
    padding: 20px 0 0 0;
}
    .mainacc_toptext.tick{
        background: linear-gradient(0deg, #ECE0F5 25.67%, #FCFCFC 135.67%);
        padding: 30px 20px 70px 20px; margin: 0 -15px; text-align: center;
        
    }
    .mainacc_toptext.tick h5{font-size: 24px;justify-content: center}
    .thank-order-detaildiv .tnk-delivery{margin: 0}
    .delivery_total{ margin: -50px 0 24px; }
    .tnkyou-maindiv .delivery_total {margin:0; }
    .order_item_desktop, .tnk-chk-lhs .hcart_tt, .mainacc_toptext.tick h5 img{display: none}
    .order_item_mobile, .track-txt{display: block}
    .mobile-payment-summary h4{ font-size: 18px;letter-spacing: 0.06em; text-transform: uppercase; }
    .mobile-payment-parent{padding: 24px 24px 35px 24px;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgb(18 18 18 / 8%);
    border-radius: 16px;}
    .mobile-payment-parent .orderitem_body_div{ padding: 0 }
    .hcart_tt_mobile h5{margin: 0; font-size: 14px; width: 100%;}
    .order_item_mobile{padding: 35px 0 0 0;}
    .mainacc_toptext.tick span { display: block;}
    .Viewreceipt .order_item_desktop {display: block}
    .Viewreceipt .hcart_tt-details h5{display: none}
    .Viewreceipt h5.mobile-order-item-title{display: block;}
    .mobile-search-body .search-glass {
    top: 14px;
    left: 12px;
    }
        .mobile-order-lhs a{ font-size: 13px;    padding: 0 14px 0 0;}
    .mobile-order-rhs a{ font-size: 13px; }
    .mobile-order-lhs a:last-child:after { height: 14px; width: 1px; left: -8px; top: 1px;}
    .mobile-order-lhs, .mobile-order-rhs a{padding: 10px 5px;}
    .first-list { padding: 30px 10px 20px; }
    .products-ordernow-action .button {
        margin-left: 8px;
    }

    .back-to-menu {
        font-size: 12px;
    }
    .back-to-menu svg {
        width: 24px;
    }
    
/*    .white-popup, .small-popup {
    max-width: 100%;
    border-radius: 16px 16px 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
        margin: 0 auto;
        overflow: auto;
    max-height: 100%;
        min-height: 200px
}
    .mfp-container {
    padding-left: 0;
    padding-right: 0;
} */
    html{
        margin-right: 0 !important;
    }
    body{
        overflow-x: hidden !important;
    }
    .ch-links ul li a .hm-dis{display: block;}
    .thankyou-main-div .ch-links ul li a strong{display: none;}
    
    .hcart_scrollarea{ 
    padding-bottom: 115px;
    }
    .cart_action {
    position: relative;
    left: 0;
    right: 0;
    bottom:0px;
    z-index: 2;
    background: #fff;
        padding: 10px 0 120px;
    }
    .hcart_dropdown{border-radius: 0}
    .cart_footer{border-radius: 0;}
    .checkout-heading span{font-size: 15px;}
    
    .prd_chosen_sub_row {
    position: relative;
    bottom: 0;
    background: #fff;
    padding: 10px 15px 10px;
    padding-bottom: 120px;
    z-index: 9;
    left: 0;
    right: 0;
}
    /* .prd_chosen_row.compo_top_div { margin-bottom: 110px;} */
    div#ProductDetailMdl{border-radius: 0;}
    .compo_acc_innerdiv {
    padding: 0 5px;
}
    .product_chosen_hea.compopro_acc_head h6{font-size: 14px; width: 100%}
    .product_chosen_item_left.product_chosen_item_left_full{padding: 15px;}
    .product_chosen_hea.compopro_acc_head{flex-wrap: wrap;padding: 10px 20px;
    border-radius: 15px;}
    .min-max-selectiontext{width: 100%; text-align: left;}
    .product_chosen_hea.compopro_acc_head .show-leveltwo-comboact{  color: #7a9ee3; font-size: 12px;}
    .prodet_baninfo {padding: 20px 20px 0 20px;}

    /* .prd_chosen_sub_row {
        bottom: calc(env(safe-area-inset-bottom));
    } */
    /* CSS specific to iOS devices only */
    /* @supports (-webkit-overflow-scrolling: touch) {
        .cart_action { 
            bottom: 0;
            padding: 10px 10px 120px 10px;
        }
    } */

    .checkout-delivery-lhs, .checkout-delivery-rhs{
        width: 100%;
        text-align: center;
    }

    .checkout-delivery-rhs p, .checkout-delivery-rhs .chk_hea{
        text-align: center;
    }

    .d_timediv, .d_timediv input {
        text-align: left;
    }

    .checkout-total .cart_img {
        width: 50px;
        margin: 0 5px 5px 0;
    }
    .banner-info-inner h2 {
    font-size: 20px;
}
    .product-title h3{font-size: 14px;}
    .pro-description{margin-bottom: 5px;}
    .cdd-details-rhs .cart_body { padding: 0;}
    .chekout_cart_bar .cart_left .cart_info {
    width: calc(100% - 60px);
}
.big-plane, .payment-de {
    max-width: 260px;
}    
.product-mobile-menu .menu-title-link {
    font-size: 13px;
}
}

@media (max-width: 420px) {
.full-login-new-body { padding: 20px 15px 20px;}
.order_delivery_item li a{font-size: 13px;}    
    .order-popup-header h3{font-size: 20px;}    
.home-option li a { padding: 6px 5px; font-size: 12px;}    
.home-option li { width: 33%;margin: 0;}    
.product-title-maindiv {
    padding: 10px;
}
    .products-ordernow-action { 
        padding: 10px;
    }
}

@media (max-width: 380px) {
    .search-dishes > ul > li {
    width: 100%;
}
    
}