@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    -webkit-text-size-adjust: none;
    padding-right: 0 !important;
    margin-right: 0 !important;
}

:focus {
    outline: 0;

}



ol,
ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
   font-family: 'Poppins', sans-serif;
    background: #fff;
    font-weight: normal;
}

cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    background-color: #fff;
    color: #555555;
     font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    margin: 0 0 10px;
    padding: 12px 15px;
    width: 100%;
}

select {
    padding: 10px 15px;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    margin: 15px 0px 10px 0px;
    padding: 14px 24px 14px;
    background: #576AAB;
    border-radius: 30px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    border: 0px;
    font-weight: 400;
    font-size: 16px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    letter-spacing: -0.375px;
     font-family: 'Poppins', sans-serif;
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover {
    background: #282728;
    color: #fff;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}


input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
    border-color:#3a5da2;
}

a {
    color: #3a5da2;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #000;
}

sup {
    top: 0;
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    line-height: 1.1;
    color: #000;
    text-transform: inherit;
    font-weight: 500;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 25px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    
    font-weight: normal
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

p {
    margin: 0 0 15px;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

.form-group {
    margin: 0 0 8px;
}

label {
    font-size: 16px;
    color: #121212;
    margin-bottom: 8px;
    display: block;
    line-height: 1;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}


.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #e80101;
}


.css-vj8t7z{border-radius: 8px}

.re_select input[type="text"] {
    margin: 0;
    height: 30px;
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}


.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}


.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #1c1c40 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #1c1c40 !important;
}

.react-datepicker__navigation {
    margin: 0;
    box-shadow: none;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 6px !important;
    background-color: #1c1c40 !important
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #fff !important;
    color: #1c1c40 !important;
    
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    color: #CC1000;
    font-size: 13px;
    position: relative;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.tab_mobtrigger {
    display: none;
}

.main_tabsec {
    margin: 0 0 40px;
}

.main_tabsec > .nav-tabs {
    border: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-radius: 50px;
    margin: 0 0 20px;
}

.main_tabsec > .nav-tabs > li {
    margin: 0;
    display: table-cell;
    float: none;
    text-align: center;
    width: 50%;
    vertical-align: middle;
    border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs > li:first-child {
    border-left: 0;
}

.main_tabsec > .nav-tabs > li > a {
    background: #b69a6b;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 15px;
}

.main_tabsec > .nav > li > a:focus {
    background: none;
}

.main_tabsec > .nav-tabs > li.active > a,
.main_tabsec .nav-tabs > li.active > a:focus,
.main_tabsec .nav-tabs > li > a:hover {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec > .nav-tabs > li > a:after {
    content: "";
    border: 18px solid #0b0405;
    border-color: #0b0405 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -36px;
    margin-left: -18px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.main_tabsec .nav-tabs > li.active > a:after {
    opacity: 1;
}

.main_tabin {
    border-radius: 4px;
}

.filter_tabsec {
    margin: 0 0 40px;
}

.filter_tabsec .nav-tabs {
    margin: 0 0 30px;

}

.filter_tabsec .nav-tabs > li {
    width: 17%;
    margin: 0;
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: middle;
}

.filter_tabsec .nav-tabs > li:first-child {
    border-left: 0;
}

.filter_tabsec .nav-tabs > li > a {
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0 0 5px;
    display: block;
   
}

.filter_tabsec .nav > li > a:focus {
    background: none;
}

.filter_tabsec .nav-tabs > li:hover > a,
.filter_tabsec .nav-tabs > li.active > a,
.filter_tabsec .nav-tabs > li.active > a:focus,
.filter_tabsec .nav-tabs > li.active > a:hover {
    border: 0;
    background: none;
    color: #060d15;
}

.filter_tabsec .nav-tabs > li > a:after {
    content: "";
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    background: #3a5da2;
}

.filter_tabsec .nav-tabs > li:hover > a:after,
.filter_tabsec .nav-tabs > li.active > a:after {
    opacity: 1;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
    border: 1px solid #ff7e91;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

/* button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
} */


.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-block;
    position: relative;
    margin: 0 30px 0 0;
}

.hmenu_list > li:hover > a,
.hmenu_list > li.active > a {
    color: #fff;
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    list-style: none;
    position: relative;
}

.hordertype_sec a {
    display: block;
    padding: 14px 5px;
    color: #fff;
    background: #3a5da2;
    text-transform: uppercase;
    position: relative;
    margin: 0 0 0 20px;
    line-height: 1;
    font-size: 15px;
    font-weight: 500;
    border-radius: 30px;
    text-align: center;
    width: 150px;
}

.hordertype_sec a:hover {
    color: #fff;
    background: #282728;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #e5dbc9;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 33px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover > ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {

    font-size: 16px;
    color: #1b0000;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #ab2225;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;

    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #d3334b;
}

.hsign_sec {
    display: none;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}

.hsign_sec > a:hover {
    color: #4872c5;
}

.htico_cart {
    position: relative;
    margin-left: 10px;
    padding-right: 5px;
}

.cart-outer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-outer > img{
    width: 21px;
}
.hcart_round {
    background: #fcd120;
    min-width: 16px;
    min-height: 16px;
    font-size: 8px;
    border-radius: 100%;
    position: absolute;
    right: -5px;
    top: -7px;
    text-align: center;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    
}

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}

.hcart_dropdown {
    width: 726px;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    z-index: 11;
    text-align: left;
        /* border-radius: 30px 0 0 30px; */
        background: white;
    overflow: hidden;
}

.hcart_dropdown.open {
    transform: translateX(0);
}

body.cart-items-open {
    overflow: hidden !important;
}

body.cart-items-open .logo-main-section:before {
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.68);
    z-index: 9;
}

.hcart_tt {
    /* background: white; */
    /* color: #fff; */
    position: relative;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    z-index: 1;
}
.hcart_tt:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 180px;
    /* background: #576AAB; */
    left: 0;
    z-index: -1;
}
.hcart_tt h3 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    /* text-transform: uppercase; */
    color: #576AAB;
    /* color: #fff; */
}

.tnk-delivery .hcart_tt h3 {
    text-transform: uppercase;
    font-size: 20px;
}

.hcart_tt h3 a:hover {
    color: #fff;
}

.hclear_cart {
    color: #fff;
}

.hcart_scrollarea {
    overflow-y: auto;
    height: 100%;
    /* background: #fff; */
    /* border-radius: 30px 0 0 0; */
    z-index: 9;
    position: relative;
}


.cart_row {
    padding: 0px;
    position: relative;
}

.cart_info h4 {
    font-size: 16px;
    margin: 0 0 2px;
    color: #121212;
}

.cart_info h4 a {
    color: #121212;
    text-transform: capitalize;
}

.cart-header-first .cart_left {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d4d4d8;
    text-align: left;
    padding-bottom: 20px;
}

.cart-header-first .cart_left p {
    font-weight: 700;
}
.cart-header-first .cart_left p span {
    display: flex;
    font-weight: 400;
    font-style: italic;
}

.cart-header-first .cart_left h4 {
    font-weight: 400;
    text-transform: none;
}

.cart-header-first .cart_left h4 span {
    display: flex;
    font-weight: 700;
    margin-top: 5px;
}

.cart_left .help-block {
    color: #454545;
    margin: 5px 0 0;
    font-size: 14px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p {
    margin: 0;
    color: #454545;
    font-size: 14px;
    text-transform: capitalize;
}

.cart_price {
    position: relative;
    margin: 0;
    text-align: right
}

.cart_price p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
}

.cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 6px;
    right: 0px;
    width: 12px;
    height: 12px;
}

.cart_remove:after {
    background: url(../images/cross1.png) no-repeat center;
    background-size: contain;
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}


.cart_remove:hover {
    background-color: #000;
}

.qty_bx {
    width: 120px;
    height: 36px;
    position: relative;
    padding: 0 38px;
    display: inline-block;
}

.qty_bx span {
    position: absolute;
    width: 36px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #fff;
    border-radius: 4px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    border: 2px solid #576AAB;
}

.qty_bx span.qty_minus {
    left: 0px;
}

.qty_bx span.qty_plus {
    right: 0px;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #0f0f0f;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    
}

.qty_bx span.qty_plus:before,
.qty_bx span:after {
    position: absolute;
    width: 12px;
    height: 12px;
    margin: auto;
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    background: #576AAB;
    left: 0;
    right: 0
}

.qty_bx span.qty_minus:after,
.qty_bx span.qty_plus:after {
    height: 2px;
}

.qty_bx span.qty_plus:before {
    width: 2px;
}

.cart_footer span {
    font-size: 15px;
}

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 12px;
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.indication_progress {
    height: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #e2e2e2;
    position: relative
}

.progress_bar {
    content: "";
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background: #29d391;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    color: #000;
    font-size: 15px;
    margin: 10px 0 0;
    text-align: center;
    font-weight: 500;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.free-delivery .progress_bar {
    background: #00CFC9
}

.minimum-order-amount .progress_bar {
    background: #74B8FF
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.cart_action {
    padding: 10px 20px;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    line-height: 0;
    /* background: #eef4ff; */
}

.product-menu-listing .cart_action {
    padding: 10px 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.breadcrumb {
    margin-bottom: 0;
    padding: 30px 0 0 0;
}

.breadcrumb_inner ul {
    list-style: none;
}

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addcart_row {
    display: none;
    margin: 0 auto;
}

.addcart_row.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.inn-product-popup {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 0 40px 0;
    justify-content: space-between;
}

.inn-product-popup .container {
    padding: 0;
}

.prodet_banleft {
    width: 100%;
}


.inn-product-popup-bg {
    position: relative;
}

.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inn-product-popup-inner {
    position: relative;
    padding-top: 0px;
    line-height: 1;
}

.inner_product_slide {
    outline: 0;
}

.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product_chosen_col {
    width: 100%;
}

.product_chosen_col.product_chosen_col_right {
    margin-right: 0;
}

.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
    margin-bottom: 0;
}

.form-group-modifier {
    margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%;
    padding-bottom: 20px;
}

.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.chosen_adn_right {
    line-height: 0;
    width: 110px;
    text-align: right;
}
.chosen_adn_left {
    width: calc( 100% - 110px );
}
.chosen_adn_right .qty_bx{ width: 110px;  }
.chosen_adn_left span {
    font-size: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 2px;
}

.radio.chosen_adn_right label {
    padding: 2px;
    text-align: center;
    height: 100%;
    line-height: 30px;
    font-size: 17px;
    
}

.product_chosen_col_right .chosen_adn {
    margin-bottom: 16px;
}

.text-box {
    width: 100%;
}

.text-box em {
    text-transform: uppercase;
    font-size: 13px;
    color: #181818;
    text-align: right;
    float: right;
    font-weight: 700;
}

.text-box textarea {
    width: 100%;
    height: 160px;
    padding: 15px 15px;
}

textarea {
    resize: none
}

.prd_chosen_sub_row {
    text-align: center;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 10px 0;
    z-index: 9;
}

.prd_chosen_sub_row > p {
    font-size: 20px;
    color: #000;
    margin:0px 0 10px;
    
    letter-spacing: 0.005em;
}

.prd_chosen_sub_col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 576px;
    margin: 0 auto;
}

.popup_addcart_cls {
    max-width: 100%;
    width: 100%;
}




.checkout-total .cart-header:after {
    display: none
}

/* .checkout-total .cdd-details-lhs {
    width: 51.09375%;
    position: relative;
    margin-right: 11.5625%
}

.checkout-total .cdd-details-rhs {
    width: 31.09375%;
    position: sticky;
    top: 110px;
} */



.checkout-billing-address .form-group, .redeem .form-group {
    max-width: 340px;
    margin: 0 0 14px;
}

.checkout-control-group-bottom {
    max-width: 500px;
}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 10px 15px;
    height: 80px;
    width: 100%;
    outline: 0;
    margin: 0;
}


.orderitem_body_div {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    padding: 24px;
}

.orderitem_body_div .cart_row .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0
}

.remark_notesec {
    margin: 20px 0 0;
    padding: 0;
}

.remark_notesec h4 {
    margin: 0 0 3px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: 15px;

}

.remark_notesec p {
    margin: 0;
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
}

.white-popup {
    max-width: 780px;
    padding: 0;
    margin: 30px auto;
}
.order_popup{
    background: #f6f6f6;
    padding: 60px 10px 40px;
}
.order-popup-header{
    text-align: center;
}
.order-popup-header h3 {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
}
.pouup_in {
    padding: 60px 50px 40px;
}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup,
.small-popup {
    max-width: 375px;
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border-radius: 16px 16px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close,
button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #181818;
    color: #fff;
    font-size: 20px;
    padding: 12px 14px;
    position: relative;
    text-align: center;

    text-transform: uppercase
}

.alert_body {
    text-align: center;
    padding: 20px;
}


.error_postal_popup .pop_title h2 {
    color: #fff;
    padding-top: 15px;
}

.error_postal_popup .pop_title small {
    color: #fff;
}


.receipt_popup,
.receipt_cateringpopup {
    max-width: 683px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
    padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
    padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
    margin-top: 0
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
    margin: 30px 0
}

.error_postal_popup .popup_ttsec {
    background: #d3334b;
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
    height: 309px;
}

.news_postal_popup .popup_ttsec {
    background: #b79a6c;
    width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
    background: url(../images/news_popupright.png) no-repeat;
    background-size: cover;
    width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
    font-size: 16px;
    margin: 6px 0 0 0;
}

.news_subscribe {
    position: relative;
    margin-top: 14px;
}

.news_subscribe .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
    background: #54362c;
    color: #fff;
}

.news_subscribe .form-control {
    height: 51px;
    border: 1px solid #fff;
}

.news_subscribe p {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body {
    padding: 32px 24px 50px 24px;
}
.self_popup .order-body{ min-height: 441px; } 


.order_delivery_item li .header-ordernow-single-img {
   margin: 0 0 8px 0;
}
.order-body p {
    color: #454545;
    font-size: 16px;
    margin: 0 0 20px 0;
    line-height: 1.25
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.order_delivery_item li {
    width: 31%;
}
.order_delivery_item li p{
    margin: 0;
}
.order_delivery_item li a {
    display: block;
    padding: 15px 10px;
    font-size: 20px;
    color: #3a5da2;
    line-height: 1;
    position: relative;
    background: #ffffff;
    border: 2px solid #3a5da2;
    border-radius: 20px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
   transition: 0.3s linear all;
    z-index: 1;
    height: 100%;
}
.order_delivery_item li .hoverstate{
    display: none;
}

.order_delivery_item li.active a,
.order_delivery_item li a:hover {
    color: #fff;
    background: #3a5da2
}
.order_delivery_item li.active a .hoverstate,
.order_delivery_item li a:hover .hoverstate{
    display: block;
}
.order_delivery_item li.active a .normalstate,
.order_delivery_item li a:hover .normalstate{
    display: none;
}

.order-body .button {
    margin-bottom: 0;
    width: 100%;
}

.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}

.order_delivery_item li .header-ordernow-single-img img {
    height: 59px;
    margin: 0 auto;
}

.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close,
#guest-popup .mfp-close, .mfp-close-btn-in .delevery_popup_datetime .mfp-close{
    background: #000;
    color: #fff;
    font-size: 0;
    width: 23px;
    height: 23px;
    line-height: 10px;
    opacity: 1;
    top: 10px;
    right: 10px;
    padding: 0;
    margin: 0;
    border-radius: 4px;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after,
.venue_popup .close-icon-but:after,
#guest-popup .mfp-close:after,
.mfp-close-btn-in .delevery_popup_datetime .mfp-close:after
{
    content: "";
    background: url(../images/close-purple.svg) no-repeat;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover, #guest-popup .mfp-close:hover, .mfp-close-btn-in .delevery_popup_datetime .mfp-close:hover {
    opacity: 0.8;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(255, 255, 255, 0.2);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(255, 255, 255, 0.6);
}

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%;
    background: #000000;
    padding: 30px 20px;
}

.awesome_popup .btn_sec {
    max-width: 500px;
    margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #000
}

.awesome_popup .popup_ttsec .button:hover {
    background: #d3334b;
    color: #fff
}

.awesome_del {
    /* margin-top: 13px; */
    text-align: center;
}

.awesome_del h5 {
    font-size: 16px;
    margin-bottom: 15px;
}

.awesome_del h2 {
    font-size: 13px;
    line-height: 19px;

}

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}
img.thumbsup {
    width: 68px;
    margin: 0 auto 15px 0;
}
.datetime_selt_lbl,
.uper-light {
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
}

.uper-light {
    margin: 0
}


.self_pop_item_loc {
    position: relative;
}


.selfpop_mapouter {
    height: 100% !important;
}
.timeslot_info_main .syd_merge {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.loc_icon {
    width: 33px;
    height: 33px;
    background: url(../images/location_btn.png) no-repeat;
    margin-right: 10px;
}

.self_pop_col_right h4 {
    font-size: 16px;
    font-weight: 700;
    color: #595656;
    text-align: left;
    margin-left: 30px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000000;
    font-size: 17px;
    text-align: left;
    margin: 0px 0 10px 5px;

    font-weight: 700
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 220px;
}

/* .self_outlet_inner li a {
    padding: 14px 0;
    display: block;
    position: relative;
    border-bottom: 1px solid #E5E5E5;
    color: #121212;
} 
.self_outlet_inner li:last-child a{ border-bottom: 0 }*/

.self_outlet_inner li a:hover {
    color: #f9a619;
}

.self_outlet_inner li.active a {
    font-weight: bold;
    
}

/* .self_outlet .button {
    margin: 0;
    width: 100%;
    display: block;

} */

.login_pop_sub .btn {
    width: 100%;
    margin-bottom: 0
}

.guest_chk_out {
    margin-left: -55px;
    margin-right: -55px;
}

.guest_chk_out .btn {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    color: #f1e0c6;
}

.guest_chk_out .btn b {
    color: #fff;
}

.guest_chk_out .btn:hover {
    color: #fff;
}

.row.pop-two-form {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;
}

.row.pop-two-form [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}


.changepw_popup {
    max-width: 480px;
}
.changepw_popup .login_pop_sub{margin-bottom: 0}

.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}


.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.mainacc_menusec {
    margin: 0 0 40px;
}


.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}


.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.congrats::after {
    display: block;
    content: "";
    clear: both;
}

.congrats-inner h3 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 10px;
}

.congrats-inner h2 {
    font-size: 42px;
    color: #fff;
    margin-bottom: 0;
}

.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.product_chosen_hea.compopro_acc_head {
    margin-bottom: 20px;
}

.product_chosen_hea.compopro_acc_head h6 {
    color: #fff;
    letter-spacing: 0.005em;
    font-size: 15px;
    width: calc(100% - 140px);
    margin-bottom: 0;
    /* text-transform: uppercase; */
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}


.cms-page {
    margin: 0px 0 30px;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.logo-main-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    align-items: center;
    flex-wrap: wrap;
}

.mbl_search_liblk {
    display: none
}

.slick-slide {
    position: relative;
}

.wppc-checked {
    color: red;
}
footer { position: relative; background: #eef4ff; padding: 50px 0 0 0; color: #000; z-index: 2;margin: 50px 0 0 0}
.home-main-div footer{ background: #fff; margin: 0; }
footer:before{ background: url(../images/wave-blue.png) repeat-x; position: absolute; top: -50px; left: 0; height: 100%; width: 100%; content: ""; z-index: -1 }
.home-main-div footer:before{ background: url(../images/wave-white.png) repeat-x;  }

.footer-top { padding: 50px 0 30px 0; display: flex; justify-content: space-between;}
.footer-newletter { width: 35.03125%;}
.footer-logo { width: 22.90625%;text-align: center}
.footer-nav { width: 32.6875%; display: flex; flex-wrap: wrap;justify-content: space-between;}
.footer-nav-inner { width: 49%;}




.footer-nav h5{  font-size: 14px;margin-bottom: 20px; text-transform: uppercase; }
.footer-nav ul{padding: 0; margin: 0; list-style: none;}
.footer-nav ul li{margin-bottom: 5px;}
.footer-nav ul li a{ color: #191919;display: inline-flex; margin-bottom: 5px; font-size: 14px;}
.footer-nav ul li a:hover{ color: #3a5da2; }
.footer-newletter h5, .company-info h5{ font-size: 15px;margin-bottom: 20px; font-weight: bold;}
.footer-newletter{font-size: 14px;}
.company-info p{margin-bottom: 10px;}
.company-info a{color: #191919;}
.company-info p:last-of-type{margin-bottom: 0}

.social-media a img { height: 33px;width: 33px; }
/*.social-media a:first-child img{ max-width: 14px }
.social-media a:nth-child(2) img{ max-width: 27px }
.social-media a:last-child img{ max-width: 40px }*/
.social-media a{display: block; margin-right: 20px}
.social-media {margin: 30px 0 0 0; display: flex; justify-content: center;}
.social-media a:last-child{margin-right: 0}
.social-media img:hover{-webkit-transform: scale(1.1);transform: scale(1.1);transition: all .2s cubic-bezier(.63,.05,.43,1.7);}
.copyright-section{ padding: 20px 0 30px 0; }
.copyright-section p {
    font-size: 14px;
    color: #000;
    text-align: left;
    margin-bottom: 0px;
}
.mobile-footer-nav{display: none}
.mobile-footer-nav .accordion {
    border: 0;
    border-radius: 0;
}
.mobile-footer-nav .accordion button.title {
    margin: 0;
    border: 0;
    box-shadow: none;
    background: #f1f1f1;
    border-radius: 0 !important;
        font-size: 15px;
    color:#000;
    padding: 18px !important;
    
    letter-spacing: 0.3px;
}
.mobile-footer-nav .accordion .accordion-item{margin-bottom: 5px}
.mobile-footer-nav .accordion .accordion-item .title:after {
    content: "\f107";
    font-size: 25px;
    color: #333;
    margin-left: 0px;
    font-family: 'FontAwesome';
    position: absolute;
    right: 20px;
    top: 12px;
    transition: 0.3s linear all;
    transform: rotate(0deg);
}
.mobile-footer-nav .accordion .accordion-item.active .title:after{ content: "\f107";transform: rotate(180deg);  }
.accordion .accordion-item.active .title{background-color: #111111;}
body .accordion .accordion-item.active .panel{ max-height: 2000px }
.mobile-footer-nav .accordion .accordion-item .panel {
    background-color: #fafafa;
    border:0;
    border-radius: 0px;
    box-shadow:none;
}
.mobile-footer-nav .accordion .accordion-item.active .panel {
    max-height:800px;
    transition: max-height 0.5s linear;
}
.mobile-footer-nav .accordion .accordion-item .panel ul{ padding:0; list-style: none;  padding: 20px;}
.mobile-footer-nav ul li a {
    color: #000;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}
.panel { margin-bottom: 10px;}
.mobile-footer-nav ul li a:hover{ color: #3a5da1; }

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}


.prd_chosen_sub_item_right button{ margin: 0; width: 100% }
.prd_chosen_sub_item_right {
    width: calc( 100% - 140px );
}
.addcart_row.prd_chosen_sub_item_left {
    width: 130px;
}
.addcart_row .btn:hover,
.addcart_row .btn:focus {
    background: #000;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-item-text h4 {
    font-size: 20px;

    color: #0a0b10;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-top: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;

    color: #000;
    margin-bottom: 0;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.menu-section_full {
    overflow: hidden;
}

header {
    position: sticky;
    top: 0;
    z-index: 99;
    margin: auto;
    background: #fff;
}

header.small_sticky {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .08);
}

.logo a {
    position: relative;

}

.mobile-cart-icon {
    display: none;
}

.working-info-parent__right {
    display: none;
}

.header-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    background: #000;
    display: none;
}

.header-bottom .header-bottom-innersec {
    position: relative;
}

ul.hmenu_list li a.active {
    color: #fff;
}

ul.hmenu_list > li > a {
    font-size: 14px;
    color: #1c1c40;
    text-transform: uppercase;
    padding:0px 0px 0;
    display: block;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    font-weight: 500;
}

ul.hmenu_list > li > a:hover,
.hmenu_list > li.active > a {
    color: #4872c5;
}

.hsign_sec > span {
    font-size: 15px;
    vertical-align: text-bottom;
    position: relative;
    top: 0px;
    color: #1c1c40;
    margin: 0 5px;
}

.hsign_sec a {
    font-size: 14px;
    color: #1c1c40;
    text-transform: uppercase;
}

.haction_sec {
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.htico_search > a > i,
.htico_search_mbl > a > i {
    background: url(../images/search.png) no-repeat center;
    width: 24px;
    height: 24px;
    display: block;
}

.header_in {
    padding-top: 45px;
    position: relative;
}


.page404-main-div {
    text-align: center;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #000;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}

.page404-main-div h1 {
    margin-bottom: 10px;
    margin-top: 40px;
}


.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


.text-center {
    text-align: center;
}


.text-uppercase {
    text-transform: uppercase
}

.hcart_dropdown .qty_bx {
        margin-top: 4px;
    order: 2;
}
.product-details .row-replace .cart_right .cart_price {
    order: 1;
}

.cart_footer p,
.cart_footer span {
    margin: 0;
}

.cart_footer {
    padding: 20px;
    /* background: #e8eef8; */
    /* border-radius: 30px 0 0 0; */
}

.cart_row.grant-total-cls .row-replace p {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.cart_row.grant-total-cls .row-replace span {
    font-size: 24px;
     font-weight: 600;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.cart-header-first h4 {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0;

}

.cart-header-first p {
    color: #000000;
    margin: 0
}

.cart-header-second h5 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second p {
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {}

.cart_footer .cart_remove:hover {
    background-color: #d3334b;
}

.product-details {
    padding: 0px 0 20px 0;
    /* border-bottom: 1px solid #ebebeb; */
    margin-bottom: 10px;
}

.product-details:last-child {
    border-bottom: 0;
}

.cart_body {
    padding: 10px 20px 0 20px;
}

.cartaction_bottom .button {
    margin: 0;
    border-radius: 10px;
}

.button.clear_selected_item {
    color: #fff;
    background: #3a5da2;
    text-decoration: none;
}

.button.clear_selected_item:hover {
    color: #fff;
    background: #1c1c40;
}

.unselect-cart-action a{
    text-decoration: underline;
}

.cart-header-first .row-replace,
.cart-header-second .row-replace,
.hcart_tt .row-replace,
.product-details .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}

.hcart_tt .row-replace {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.hcart_dropdown.open .hcart_tt .row-replace {
    justify-content: space-between;
}

.hcart_tt .cart-hdr-top {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

#cart-close-span {
    display: block;
    position: absolute;
    order: 2;
    width: 10px;
    height: 10px;
    cursor: pointer;
    right: 31px;
    top: 20px;
    background: url(../images/model-close.svg) no-repeat;
}


.hcart_tt .row-replace h3 {
    font-size: 21px;
}

.cart-header-first .row-replace .col-sm-cls,
.cart-header-second .row-replace .col-sm-cls {
    width: 50%
}

.cart_left {
    padding: 0 5px 0 0
}

.cart_right {
    padding: 0 0 0 5px
}

.cart-outlet-name, .outlet-name {
    background: #e8eef8;
    margin: 10px 0;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    color: #3a5da2;
    border: 1px solid #e8eef8;
    border-radius: 12px;
    /* width: calc(100% - 140px);
    width: -webkit-calc(100% - 140px); */
    width: 100%;
    font-weight: 600;
}

.cart-outlet-name a,
.outlet-name a {
    display: block;
    padding: 4px 18px 4px 12px;
    position: relative
}

.cart_body .hcart_tt .row-replace {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cart_body .hcart_tt {
    padding: 8px 10px 8px 10px;
    background: #060203;
}

.cart_body .hcart_tt h3 {
    text-transform: uppercase;
    font-size: 18px;

    color: #fff;
}

.cart_body .hclear_cart:hover {
    color: #b1cbf8;
    /* border-color:  #b1cbf8 */
}

.product-details .row-replace .cart_right {
    width: 125px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}


.product-details .row-replace .cart_left {
    width: calc(100% - 130px);
    width: -webkit-calc(100% - 130px);
    /* padding: 0 0 0 28px; */
    padding: 0;
}

.product-details .row-replace .cart_left input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin: 0;
    border-color: #333;
}

.product-details .row-replace .cart_left input[type=checkbox]:after {
    width: 4px;
    height: 8px;
    border-width: 1px;
    left: 4px;
    top: 1px;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}


.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart_row.cart-header-second {
    margin:25px 0 0 0;
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.innersection_wrap {
    padding: 40px 0 100px 0;
}


.myacc_order_details ul {
    list-style: none;
    padding: 0;
}

.current_order .myacc_order_details ul li {
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border-radius: 16px;
    background: #fff;
    padding: 18px;
    position: relative;
    margin: 0 0 16px 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.order-lhs {
    padding: 0 0 0 34px;
    position: relative;
    width: calc(100% - 150px);
}

.order-lhs:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: url(../images/spoon.svg) no-repeat;
    width: 24px;
    height: 24px;
}

.order-lhs p {
    
    margin: 0 0 5px;
}

.order-lhs span {
    font-size: 14px;
    color: #454545;
}

.order-rhs {
    width: 140px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.order-rhs span, .order-status span {
    font-size: 12px;
    border-radius: 8px;
    display: block;
    padding: 4px 8px;
    text-align: center;
}
.order-status span{display: inline-block}

.order-rhs .order-received, .order-status .order-received  {
    background: #377feb;
    color: #fff;
}
.order-rhs .order-processing, .order-status .order-processing    {
    background: #32b0a3;
    color: #fff;
}
.order-rhs .order-delivering, .order-status .order-delivering   {
    background: #e29f35;
    color: #fff;
}
.order-rhs .order-completed, .order-status .order-completed  {
    background: #38a52f;
    color: #fff;
}
.order-rhs .order-cancelled, .order-status .order-cancelled   {
    background: #b80707;
    color: #fff;
}
.order-rhs .order-collected, .order-status .order-collected  {
    background: #083ed4;
    color: #fff;
}
.order-rhs .order-packed, .order-status .order-packed  {
    background: #b8a900;
    color: #fff;
}
.order_details_body {
    padding: 15px;
    background: #fff;
}

.order_no_deatails {
    margin: 0 0 20px 0;
    text-align: center
}

.order_no_deatails span {
    display: block;
}

.promolisting-page ul{
    padding: 0; 
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.promo-earned-col {
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border-radius: 16px;
    width: 32.5%;
    margin: 0 1.25% 32px 0;
    overflow: hidden;
    cursor: pointer;
}

.promo-earned-col:nth-child(3n) {
    margin-right: 0;
}

.promo-form {
    text-align: center;
    padding: 40px 15px;
    margin: 0;
    background: #fafafa;
}

.promo_wrap .both-order-tab {
    padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-form input[type="text"] {
    margin: 0;
    height: 47px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
    border-radius: 30px;
}

.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 15px;
    background: #3a5da2;
    padding: 13px 20px;
    height: 47px;
    min-width: 146px;
    color: #fff;
}

.promo-form .applyBtn:hover {
    background: #282728;
}

.promo-form h3 {
    color: #fff;
    font-size:28px;
    margin: 0 0 15px;
    line-height: 1.3
}
.mypromo-main-div ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.promation_btn .button {
    margin: 0 5px;
    padding: 10px 20px;
    font-size: 13px;
}

.promation_btn {
    padding: 10px 0 0 0;
}
.promo-earned-code {
    font-size: 16px;
    margin-bottom: 8px;
}

.promo-earned-col-image {
    position: relative;
    background: #ECE0F5;
}

.promo-earned-col-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    max-height: 278px;
    object-position: center;
}

.promo-earned-col-item {
    background: #fff;
}

.promo-earned-info {
    padding: 16px;
    position: relative;
    text-align: center;
}
.promo-earned-code span{display: block; margin: 5px 0 0 0;}




.pickup-cart-div .cart-direction:after {
    display: none
}

.pickup-cart-div .cart-direction img {
    width: 75px;
    margin: 0 auto
}

.pickup-cart-div .cart-header-first .row {
    display: block;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}



.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.white_bgbx {
    background: #fff;
    padding: 30px 25px;
}

.brand_note {
    margin: 0 0 70px;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

#scrollbutton {
    position: fixed;
    bottom: 70px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #000;
    display:flex;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 1;
    overflow: hidden;
    font-size: 20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
}

#scrollbutton a:hover {
    background: #1c1c40;
    color: #fff;
}

.horder_tt {
    padding: 12px 10px 12px 20px;
    height: auto;
}

.oreder-row-inv {
    margin: 0;
}

.oreder-row-inv .cart_img {
    padding: 0;
}

.Viewreceipt .oreder_itm_row_inner {
    padding: 5px 20px;
}

.Viewreceipt .outlet-name {
    margin-bottom: 10px
}

.oreder-row-inv .cart_left {
    text-align: left;
}

.month-select-div,
.choose-date-div {
    margin: 0 auto 40px auto;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
    display: block;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 20px 0;

}

.catering-product-rowlist .price_nor {
    font-size: 22px;
    display: block;
    padding: 10px 0 0 0;

    
}

.error_div_cls {
    color: red;
    display: none
}


.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

h3.choose-eventtype {
    position: relative;
}

.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 450px;
    margin: auto !important;
    padding: 30px;
    font-size: 22px;
    border-radius: 10px;

    display: inline-block;
    text-align: center;
}

.custom_center_alertcls.alert-danger {
    color: #ca322f;
    background-color: #fdd1d1;
}

.mfp-custom-close {
    position: absolute;
}

img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}

img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}

.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}

.text-lbl {
    display: block;
    
    text-transform: uppercase;
    margin: 0 0 5px 0;
}

.mk-catering-payment .chk-payment-col-radio li {
    margin-right: 0
}

.price_nor dl {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;

    text-transform: uppercase;
}

.ordrdatetime_error .error {
    width: 100%;
}

.ordrdatetime_error_pickup .error {
    width: 62%;
}

.ordrdatetime_error_pickup {
    text-align: center;
}

.syd_date {
    width: 100%;
}

.syd_time.delivery_submit_cls {
    width: 100%;
}

.syd_merge .form-group {
    margin: 0
}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}

.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}

.datetime_selt_sec .react-datepicker-wrapper,
.datetime_selt_sec .react-datepicker__input-container {
    display: block;
}

body .syd_date .react-datepicker {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgb(0 0 0 / 10%);
    border-radius: 13px;
    border: 0;
}

body .react-datepicker__header {
    background-color: inherit;
    /*border-bottom: 0;*/
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 16px 16px 5px 16px;
}
.order-body .form-group .react-datepicker__header{ border-bottom: 0; }


body .react-datepicker--time-only {
    width: 100%;
    margin: 30px 0 0 0;
}

body .react-datepicker__month-container {
    width: 100%;
    display: block;
    margin: 0 auto;
    float: none;
}

body .react-datepicker__current-month {
    text-align: left;
    padding-left: 10px;
    padding-bottom: 5px;
    letter-spacing: 0.38px;
    font-size: 18px;
}

body .react-datepicker__day-name {
    color:#333;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: -0.078px;
}

body .react-datepicker__day,
body .react-datepicker__time-name {
    color: #121212
}

body .react-datepicker__day--disabled,
body .react-datepicker__month-text--disabled,
body .react-datepicker__quarter-text--disabled {
    color: #ABABAB;
}

body .react-datepicker__day--selected,
body .react-datepicker__day--in-selecting-range,
body .react-datepicker__day--in-range,
body .react-datepicker__month-text--selected,
body .react-datepicker__month-text--in-selecting-range,
body .react-datepicker__month-text--in-range,
body .react-datepicker__quarter-text--selected,
body .react-datepicker__quarter-text--in-selecting-range,
body .react-datepicker__quarter-text--in-range {
    color: #fff
}

body .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
    text-align: left;
}

body .react-datepicker__time-container {
    border: 0;
    float: none;
    width: 100%
}

body .react-datepicker__time-list-item--disabled {
    display: none
}


body .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: inherit;
    min-height: 24px;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 10px;
}

body .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    max-height: 240px;
    height: inherit;
}

body .react-datepicker__day-name,
body .react-datepicker__day,
body .react-datepicker__time-name {

}
body .react-datepicker{     font-family: 'Poppins', sans-serif;  }


body .react-datepicker__navigation {
    border: 0;
    width: 10px;
    height: 18px;
    background: url(../images/chevron.forward.svg) no-repeat;
    top: 20px;
}

body .react-datepicker__navigation--previous:hover,
body .react-datepicker__navigation--next:hover {
    background: url(../images/chevron.forward.svg) no-repeat;
    opacity: 0.4
}

body .react-datepicker__navigation--next {
    right: 16px
}

body .react-datepicker__navigation--previous {
    transform: rotate(180deg);
    right: 50px;
    left: inherit;
}

.featured-products-main > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0
}

.featured-products-main > ul > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    width: 23.5%;
    margin: 0 2% 20px 0;
    background: #fff;
}

.featured-products-main > ul > li:nth-child(4n) {
    margin-right: 0
}

.products-image-div {
    height: 220px;
    padding: 0px;
    position: relative;
}

.product-info-div {
    padding: 10px 20px 130px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 220px);
    background: #fff;
    transition: all .3s linear;
        text-align: center;
}

.restpage-main-div .productlist-main-div .products-list-ulmain > li .product-info-div {
    display: block
}

.products-image-div img,
.gift-products-image-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.product-title h3 {
    font-size: 18px;
    margin: 0 0 15px 0;
    font-weight: bold;
    letter-spacing: -0.45px;
    text-transform: capitalize;
}

.horizontal-view .product-title h3, .search-outlet-popup .search-result-mobile li .product-title h3 {
    color: #121212;
    font-weight: bold;
    font-size: 16px;
    
}

.product-short-description {
    margin: 0 0 2px 0
}

.product-short-description p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: #454545;
}

.featured-products-main .owl-stage,
.innerproduct .products-list-ulmain .owl-stage {
    display: flex;
}

.product-price h3 {
    margin: 0;
    font-size: 18px;
}

.product-originale-price {
    font-size: 14px;
    letter-spacing: 0;
    color: #454545;
    text-decoration-line: line-through;
    font-weight: normal !important;
    margin: 4px 0 0 0
}

.products-ordernow-action .button {
    margin: 0px;
    max-width: 160px;
    width: 100%;
    padding: 8px 10px 8px;
}
.products-ordernow-action .addcart-row-child .order_done{ 
    margin: 0 0 0 5px;
    padding: 11px 20px;
    font-size: 14px;
    width: auto;
    vertical-align: top;
}

.products-ordernow-action {
    margin: 0;
    line-height: 1;
    position: absolute;
    bottom: 20px;
    left: 5px;
    right: 5px;

}
.product-price{
    margin: 0 0 10px 0;
}

.product-short-description p {
    margin: 0;
}

.products-single-li {
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: 0px;
    box-shadow: 0px 4px 15px rgba(18, 18, 18, 0.08);
    height: 100%;
    background: #fff;
}

.featured-products-main .owl-carousel .owl-stage-outer,
.products-list-ulmain .owl-carousel .owl-stage-outer {
    padding: 5px 0 20px 0;
}

.featured-products-section h4{ margin-bottom: 15px; font-weight: bold}

.featuredpro-section .owl-dot{ border-color: #fff; }
.featuredpro-section .owl-dot.active, .featuredpro-section .owl-dot:hover{ border-color: #fff; background: #fff}

.products-ordernow-action .button.disabled,
.products-ordernow-action .button.disabled:hover {
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;
   /*  background: url(../images/plus-rounded-light.svg) no-repeat; */
}
/*
.products-ordernow-action .button.disabled:after,
.products-ordernow-action .button.disabled:hover:after {
    background: url(../images/plus-radius-light.svg) no-repeat
}
*/
.hmenu-login-section {
    display: flex;
    align-items: center
}

.spm-lhs .search-rest {
    margin-bottom: 0;
    padding:5px 5px 5px 45px;
    border: 2px solid #eeecec;
    border-radius: 40px;
    height: 40px;
    font-size: 14px;
}
.hmenu-login-section .search-top input{
  margin-bottom: 0;
        padding-left: 50px;
    border-radius: 30px;
}


.hmenu-login-section > ul {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-menu-listing {
    display: flex;
}

.product-menu-listing .productlist-main-div {
    width: 60%;
}

.product-menu-listing .htico_cart {
    width: 40%;
    list-style: none;
    padding-left: 20px;
}

.product-menu-listing .hcartdd_trigger {
    display: none;
}

.product-menu-listing .hcart_dropdown {
    background: #eef4ff;
    position: static;
    top: 172px;
    transform: none;
    width: 100%;
    height: auto;
    border-radius: 20px;
    z-index: 8;
}

.product-menu-listing .hcart_scrollarea {
    height: auto;
    z-index: 1; 
    border-radius: 30px 30px 0 0;
}

.product-menu-listing .hcart_dropdown .cart_action {
    position: static;
}

.product-menu-listing .cart_body {
    padding-bottom: 10px;
}
.product-menu-listing .cart_footer {
    border-radius: 30px 30px 0 0;
}

.product-menu-listing #cart-close-span {
    display: none;
}

.product-menu-listing .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product-menu-listing .container:before,
.product-menu-listing .container:after,
.checkout-header .container:before,
.checkout-header .container:after {
    display: none;
}

.productlist-main-div {
    width: 100%
}

.menu-section-left {
    width: 300px;
    position: relative
}

.productlist-main-div .products-list-ulmain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
}

.productlist-main-div .products-list-ulmain > li {
    width: 32%;
    margin: 0 2% 20px 0;
    background: #fff;
    transition: 0.4s linear all;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: auto;
}

.productlist-main-div .products-list-ulmain > li:nth-child(3n) {
    margin-right: 0
}


.product-outlet-name {
    color: #f9a619;
    font-size: 15px;
    line-height: 1;
    margin-bottom: 8px;
    
}
.product-outlet-name a{
    color: #3a5da2
}
.product-title h3 {
    min-height: 40px;
}
.product-menu .menu-list-intvl a {
    padding: 0;
    position: relative;
    cursor: pointer;
    display: flex;
    color: #121212;
    font-size: 15px;
   font-weight: 500;
}

.product-menu .menu-list-intvl a:hover,
.product-menu .menu-list-intvl.active a {
        color: #3a5da2;
}

.product-mobile-menu {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.product-mobile-menu .menu-list-intvl {
    display: inline-flex;
    white-space: nowrap;
}

.product-mobile-menu .menu-list-intvl a {
    padding: 0;
    position: relative;
    cursor: pointer;
    display: flex;
    color: #121212;
    font-size: 15px;
    font-weight: 500;
}

.product-mobile-menu .menu-list-intvl a:hover,
.product-mobile-menu .menu-list-intvl.active a {
        color: #3a5da2;
}


.progress_bar_div {
    margin-top: 10px
}

.common-inner-banner {
    position: relative;
   
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    flex-flow: column wrap;
    line-height: 1;
    color: #fff;
    margin: 0 0 30px 0;
    
}

.common-inner-banner p {
    font-size: 30px;
    text-transform: uppercase;
    z-index: 1;
    letter-spacing: 0.06em;
    
    margin: 0 auto;
    text-align: left;
    max-width: 1320px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
        position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.common-inner-banner img{ width: 100%; /*height: 100%; object-fit: cover;position: absolute; left: 0; top: 0;*/ }


.common-inner-blckdiv {
    position: relative;
}

.common-inner-blckdiv .client-info-tech {
    bottom: 50px;
    top: inherit
}

.prodet_bansec {
    width: 100%;
}

.inn_product_row {
     width: 100%;
    padding: 38px 40px 0 40px;
}


.detail-pg-slider .slick-track .slick-slide > div {
    margin: 30px 7px;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    width: 100%;
    text-align: center;
    overflow: hidden;
}

.detail-pg-slider .product-title h3 {
    font-size: 21px;
    margin: 0;
}

.addonpro-slider-top {
    margin: 70px 0 0px 0
}

.chk-payment-title h3,
.chk-addonpro-title h3 {
    font-size: 24px;
    text-transform: uppercase;
    text-align: center
}

.detail-pg-slider .product-tag-list {
    bottom: -8px;
}

.chk-addonpro-title h3 {
    margin-bottom: 0px
}

.addcart_row:after {
    clear: both;
    content: "";
    display: table;
    overflow: hidden;
}

.birthday-info-div {
    padding: 25px 20px 10px 20px;
    text-align: center;
}

.birthday-info-top h3 {
    font-size: 43px;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-top: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.birthday-info-top h4 {
    margin-bottom: 0;
    font-size: 26px;
    font-style: italic;
}

.birthday-info-top h3 img {
    margin: 0px 0 0 15px;
    position: relative;
    top: -5px;
}

.birthday-info-middle {
    padding: 10px 0 0 0;
    font-size: 22px;
    color: #000
}

.birthday-info {
    text-align: center;
    margin-bottom: 20px;
}

.left-input-div,
.right-input-div {
    max-width: 280px;
    width: 100%;
}

.birthday-inpt-act {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 570px;
    margin: 0 auto;
}

.narml_select {
    position: relative;
}

.narml_select:after {
    width: 30px;
    height: 46px;
    content: "";
    position: absolute;
    right: 1px;
    background: #fff;
    top: 1px;
}

.normal-popup {
    max-width: 680px;
    text-align: center;
}

.normal_popup_cont ul {
    padding: 0;
    list-style: none
}

.normal-popup button.mfp-close {
    background: #d3334b;
    opacity: 1;
    color: #fff;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    line-height: 1;
}

.promopopup-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#promo-check-popup {
    max-width: 810px;
    background: none;
}

.promopopup-rhs {
    width: 53.08641975308642%;
    border: 1px solid #d3334b;
}

.promopopup-lhs {
    width: 46.91358024691358%;
    border-top: 30px solid rgba(0, 0, 0, 0);
    border-bottom: 30px solid rgba(0, 0, 0, 0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.frm-action-div {
    background: #d3334b;
    color: #fff;
    padding: 40px 30px;
}

.promopopup-rhs img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.promopopup-lhs .frm-top-title h3 {
    color: #fff;
    margin-bottom: 0px;
}

.promopopup-lhs .frm-inputbtn-div {
    position: relative;
}

.promopopup-lhs .frm-inputbtn-div .button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 48px;
    background:
        #000;
    font-size: 15px;
    padding: 10px 14px 10px;

    
    border: 1px solid #fff;
}

.promopopup-lhs .form-group input {
    border-color: #fff;
    padding-right: 110px;
}

.promopopup-lhs .frm-bottom-text {
    text-transform: uppercase;
    font-size: 14px;
    
    margin-bottom: 0
}

.promopopup-lhs .frm-bottom-text p {
    margin: 0
}

.promopopup-lhs .form-group {
    margin-bottom: 5px;
}

#promomail-error {
    color: #b70000;
    line-height: 1;
    font-size: 13px
}

.promo-check-popup button.mfp-close {
    background: #d3334b;
    opacity: 1;
    color: #fff;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    line-height: 1;
    top: -15px;
    right: -15px;
}

.prodetailinner-main-div button.close,
.sharegroup-popup button.close,
.prodetailpoup-header button.popup-close-icon {
    width: 26px;
    height: 26px;
    background: transparent;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    top: 10px;
    right:20px;
    font-size: 0;
    position: absolute;
    margin: 0;
    padding: 0;
}

.prodetailinner-main-div button.close:before,
.sharegroup-popup button.close:before,
.prodetailpoup-header button.popup-close-icon:before {
    position: absolute;
    right: 0;
    content: ' ';
    height: 10px;
    width: 10px;
    top: 0;
    background: url(../images/model-close.svg) no-repeat;
    color: white;
    left: 0;
    bottom: 0;
    margin: auto;
}

.prodetailpoup-container {
    overflow-y: auto;
    height: 100%;
}

#ProductDetailMdl .modal-content {
    border-radius: 0;
    border-color: #fff;
}


.mbl-hiden {
    overflow-y: hidden !important;
}

.htico_search_mbl {
    display: none
}

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background-color: #fff;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 99999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 80px 40px 40px;
    height: 100%
}
.mobile-menu .mobile-menu-body .mainacc_menulist{
    padding: 0 0 20px 0;
}
.mobile-menu .mobile-menu-body .mainacc_menulist li{
    padding: 15px 0
}

.mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.active .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.active .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.active .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.active .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.active .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.mobile-menu.active .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}

.mobile-menu.active .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #000;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}

.mobile-menu .mobile-menu-logo {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1
}

.mobile-menu .mobile-menu-close {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2
}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: flex;
    width: 20px;
    height: 20px;
    position: relative;
    text-align: center;
    background: #000;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.mobile-menu .mobile-menu-header {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 0;
    border-bottom: .0625rem solid #E4E4E4;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 80px;
    background: #ffffff;
    z-index: 2
}
.mobile-menu .mobile-menu-logo a img {
    width: 50px;
}
.mobile-menu .menu a {
    display: block;
    font-size: 16px;
    color: #000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    padding: 1rem 0;
    line-height: 1;
    text-transform: inherit;
    font-weight: 600;
}

.mobile-menu .menu .sub-menu {
    display: none;
    padding: 0
}

.mobile-menu .menu .sub-menu li {
    padding-right: 0
}

.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}

.mobile-menu .menu .sub-menu a {}

.mobile-menu .menu li {
    border-bottom: .0625rem solid #dbd9d2;
    padding-right: 40px
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.pkselect_tabsec .tab-content {
    background: #f9f7f8;
    padding: 0px 25px 20px;
}

.rest-list-cls {
    margin-top: 20px;
}

.li-full-width {
    width: 100% !important;
    ;
}

.btn-act-clss {
    color: #fff;
    background: #000;
    border: 1px solid #d3334b;
}

#delivery-continue-link,
#takeaway-continue-link {
    display: none;
}


/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0), 0 0 2px 0 rgba(255, 0, 0, .5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0, 0%, 100%, 0), 0 0 2px 0 rgba(255, 0, 0, .5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup {
    max-width: 340px;
    border-radius: 10px;
    background: #f5f5f7
}

span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3
}

.omise_pay_body {
    padding: 30px;
    position: relative;
    border-top: 2px solid #fff;
}

.omisepop_in .form-control {
    padding: 10px;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
    padding-left: 36px
}

.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}

.omisepop_in .alt_btns .button {
    margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;
}

.omisepop_in .form_sec i {
    position: absolute;
    left: 10px;
    color: #888;
    top: 12px;
}

.omisepop_in .form_sec > div {
    position: relative
}

.omisepop_in .form_sec .uname-box i {
    left: 13px;
}

.omisepop_in .form_sec .card-numbr-div i,
.omisepop_in .form_sec .expire_left i {
    font-size: 16px;
    top: 13px;
}

.omisepop_in .form_sec .expire_right i {
    font-size: 19px;
    top: 12px;
}

.expire_mdl .form-control {
    padding-left: 15px
}

.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}

.visa + .card {
    background-position: 0 -19px;
}

.master + .card {
    background-position: 0 -38px;
}

.discover + .card {
    background-position: 0 -57px;
}

.american + .card {
    background-position: 0 -76px;
}

.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    line-height: 21px;
    background: #a5a5a5;
    border: 1px solid #929292;
    top: 10px;
    font-size: 20px;
    right: 10px;
    text-align: center;
}

.pay-omiseconf-popup button.mfp-close:hover {
    background: #000;
    border-color: #000;
}

.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding: 9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}

.omise-error-info-div {
    color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}

.omise-error-icon {
    font-size: 14px;
}

.omise_alert_close {
    position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}

.omise-error-msg {
    margin: 0 0 0 11px;
    font-size: 14px;
}

.omise-error-info-div .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}

.displayDivCls {
    display: none;
}

.white-home {
    position: relative;
    z-index: 2;
}

.home-banner {
    position: relative;
}

.restpage-main-div .productlist-main-div .products-list-ulmain > li {
    text-align: left;
    cursor: pointer;
}

.product-title span {
    color: #949292;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    display: inline-block;
    margin: 5px 3px 0 0;
}

.distance-merge {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #949292;
    font-size: 14px;
    text-transform: uppercase;
    padding-bottom: 11px;
    padding-top: 10px;
}

.product-info-div .promo span:nth-child(4n) {
    margin-right: 0;
}

.product-info-div .promo {
    display: flex;
    flex-wrap: wrap;
}

.product-info-div .promo span {
    display: block;
    font-weight: 700;
    border: 1px solid #f7c30d;
    border-radius: 5px;
    padding: 7px 2px;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    width: 23.828125%;
    margin: 5px 1.5625% 0 0;
}

.distance-merge .distance,
.restaurant-other-info .distance span {
    position: relative;
    padding: 0 0 0 30px
}

.distance-merge .distance:before,
.restaurant-other-info .distance span:before {
    position: absolute;
    left: 0;
    top: -3px;
    width: 22px;
    height: 22px;
    content: "";
    background: url(../images/away-map.png) no-repeat;
}

.search-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    /* padding-bottom: 30px; */
}

.search-product > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative
}

.search-product > .container:before,
.search-product > .container:after,
.menu-top-section-inner .container:before,
.menu-top-section-inner .container:after {
    display: none
}

.restaurant-details-rhs .menu-top-section-inner {
    padding:25px 10px 25px 10px;
    position: sticky;
    top: 100px;
    background: #fff;
    z-index: 9
}

.search-product .custom_alertcls {
    position: absolute;
    right: 0;
    top: 100%;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
}

.search-product .custom_alertcls:after {
    bottom: 100%;
    right: 45%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #f2dede;
    border-width: 6px;
}

.search-product .custom_alertcls p {
    margin: 0
}

.spm-lhs {
    position: relative;
    width: 100%
}

.spm-rhs {
    width: 162px;
}

.spm-rhs .filter-area {
    padding-right: 45px;
    border: 0;
    box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.10);
    height: 52px;
    padding-left: 18px;
    font-size: 18px;

    padding: 12px 40px 12px 18px;
    position: relative;
    width: 100%;
    background: #fff;
    cursor: pointer;
    cursor: pointer;
}

.spm-rhs {
    width: 162px;
    margin-left: 10px;
}

.spm-lhs .search_result ul {
    padding-bottom: 0;
}

.filter-area img {
    position: absolute;
    right: 15px;
    top: 16px;
    width: 17px;
}

.filter-area img.filter-clear-image {
    width: 12px;
    top: 20px;
}

.search-product-main {
    position: relative;
}

.filter-details {
    position: absolute;
    z-index: 9;
    width: 100%;
    left: 0;
    top: 105%;
    display: none;
    max-height: 600px;
    overflow-y: auto;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
    background: #fff;
    padding: 20px;
}

.filter-details.active {
    display: block
}

.common-filter {
    padding-bottom: 20px;
}

.common-filter:last-child {
    padding-bottom: 0
}

.common-filter ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.common-filter ul li {
    width: 23.828125%;
    margin: 0px 1.5625% 15px 0;
    cursor: pointer;
}

.common-filter ul li:nth-child(4n) {
    margin-right: 0
}

.common-filter h3 {
    font-weight: 700;
    font-size: 17px;
    margin: 0 0 15px 0;
}

.common-filter.item-offer ul li {
    display: block;
    font-weight: 700;
    border: 2px solid #d3334b;
    border-radius: 5px;
    padding: 10px 5px;
    line-height: 1;
    text-align: center;
}

.clear-filter {
    position: absolute;
    right: 20px;
    top: 25px;
    line-height: 1;
    font-size: 12px;
}

.new-header-first {
    text-align: center;
    padding-bottom: 25px;
}

.new-header-first h4,
.new-header-second-inner h5 {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 12px;
}

.new-header-first p,
.new-header-second-inner span {
    margin: 0;
    border-bottom: 1px solid #cdcdcd;
    font-size: 14px;
    padding-bottom: 5px;
}

.new-header-second {
    text-align: center;
    padding-bottom: 30px;
}

.new-header-second-inner p {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
}

.new-header-second-inner span {
    display: block;
    width: 47%;
}

body.cart-items-open .scrolltop {
    display: none !important
}

.htico_cart #cart-success-msg {
    position: absolute;
    right: 0;
    min-width: 310px;
    padding: 10px 15px;
    background: #00b894;
    color: #fff;
    border-radius: 10px;
    line-height: 1;
    bottom: -45px;
}

.htico_cart #cart-success-msg:after {
    bottom: 100%;
    right: 4%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #00b894;
    border-width: 7px;
}

.htico_cart #cart-success-msg p {
    margin: 0;
    width: calc(100% - 20px);
    float: right;
    line-height: 13px;
    font-size: 11px;
}

.htico_cart #cart-success-msg a {
    color: #fff;
    position: relative;
    top: 0;
}

.event-menu-listing {
    padding: 30px 0 0 0;
}

img.pop-bag-img {
    width: 80px;
    margin: 0 0 15px 0;
}

.outlet-product-parent {
    padding: 0 20px 20px 20px
}

.tnk-delivery .cart_price p {
    font-size: 14px;
}

.congrats {
    padding: 20px 0 0 0;
}

.order-delivery .head_right::before {
    background: #d3334b;
}

.order-delivery .head_right h4 {
    color: #fff
}

.restaurant-details {
    padding: 30px 0 20px 0;
    background: #fff
}

#warning-max-orderpopup h3 {
    font-size: 32px;
    margin-bottom: 15px;
}

#warning-max-orderpopup p {
    color: #000;
    padding: 0;
}

.promotion-only-show .text-right span {
    padding-right: 20px
}

.mobile-login {
    display: none
}

.mobile-login ul {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 110%;
    left: 0;
    background: #d3334b;
    border-radius: 6px;
    min-width: 150px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 5;

}

.mobile-login ul.active {
    visibility: visible;
    opacity: 1;

    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.mobile-login {
    position: relative;
}

.mobile-login ul li a {
    display: block;
    line-height: 1;
    padding: 10px 20px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}

.product-mobile-menu-nav {
    /* display: none */
}

a.controller-nav img {
   width: 30px;
    position: relative;
    top: 2px;
}


.notice-board {
    background:#1c1c40;
    padding: 7px 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500
}

.notice-board p {
    margin: 0
}

.menu_icon {
    display: none;
}

/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {

    input[type=checkbox],
    input[type=radio] {
        --active: #3a5da2;
        --active-inner: #fff;
        --focus: 2px rgba(0, 0, 0, 0);
        --border: #282728;
        --border-hover: #3a5da2;
        --background: #fff;
        --disabled: #787878;
        --disabled-inner: #787878;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 24px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 2px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

    input[type=checkbox]:after,
    input[type=radio]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }

    input[type=checkbox]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    input[type=radio]:checked {
        --b: var(--active-inner);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    input[type=checkbox]:disabled,
    input[type=radio]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.8;
    }

    input[type=checkbox]:disabled:checked,
    input[type=radio]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }

    input[type=checkbox]:disabled + label,
    input[type=radio]:disabled + label {
        cursor: not-allowed;
    }

    input[type=checkbox]:hover:not(:checked):not(:disabled),
    input[type=radio]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }

    input[type=checkbox]:focus,
    input[type=radio]:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    input[type=checkbox]:not(.switch),
    input[type=radio]:not(.switch) {
        width: 24px;
    }

    input[type=checkbox]:not(.switch):after,
    input[type=radio]:not(.switch):after {
        opacity: var(--o, 0);
    }

    input[type=checkbox]:not(.switch):checked,
    input[type=radio]:not(.switch):checked {
        --o: 1;
    }

    input[type=checkbox] + label,
    input[type=radio] + label {
        cursor: pointer;
        margin-left: 8px;
    }

    input[type=checkbox]:not(.switch) {
        border-radius: 6px;
    }

    input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 12px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 8px;
        top: 3px;
        transform: rotate(var(--r, 20deg));
    }

    .checkout-billing-address input[type=checkbox]:not(.switch):after {
        left: 7px;
        top: 2px;
    }

    input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }

    input[type=checkbox].switch {
        width: 38px;
        border-radius: 11px;
    }

    input[type=checkbox].switch:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
    }

    input[type=checkbox].switch:checked {
        --ab: var(--active-inner);
        --x: 17px;
    }

    input[type=checkbox].switch:disabled:not(:checked):after {
        opacity: 0.6;
    }

    input[type=radio] {
        border-radius: 50%;
    }

    input[type=radio]:after {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: var(--active);
        opacity: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    input[type=radio]:checked {
        --s: .5;
    }
}



.outlet-listing {
    padding: 0 0 50px 0;
}

.outlet-listing-rhs {
    width: 100%;
}

.outlet-listing-rhs > ul,
.search-resturent > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.outlet-listing-rhs > ul > li,
.search-resturent > ul > li {
    width: 24.375%;
    margin: 0 0.78125% 20px 0;
}
.outlet-listing-rhs > ul > li:nth-child(4n),
.search-resturent > ul > li:nth-child(4n){
    margin-right: 0
}

.outlet-listing-rhs > ul > li > a,
.search-resturent > ul > li > a {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #eaeaea;
    padding: 12px 10px;
    border-radius: 15px;
    box-shadow: 0 2px 13px 0 rgba(0,0,0, 0.07);
    transition: 0.4s linear all;
    height: 100%;
}
.outlet-listing-rhs > ul > li.active > a,  .search-resturent > ul > li.active > a,
.outlet-listing-rhs > ul > li > a:hover,
.search-resturent > ul > li > a:hover{
    border-color:#f9a619;
    box-shadow: none;
}

.search-resturent > ul > li {
    width: 25%
}

.outlet-img {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 8px auto;
    position:relative;
}
.outlet-img:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border: 2px solid #fff;
}
.outlet-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.outlet-content {
    width: 100%;
}

.outlet-content h5 {
    color: #282728;
    text-transform: inherit;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    text-align: center;
}

.outlet-listing-rhs > ul > li > a:hover .outlet-content h5 {
    color: #3a5da2;
}

.outlet-content p {
    color: #1c1c40;
    margin-bottom: 3px
}

.outlet-content span {
    font-size: 13px;
    color: #1c1c40;
    display: block
}

.filter-option {
    margin: 0 0 40px 0;
}

.filter-option ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.filter-option ul li {
    margin: 0 0 16px 0;
    position: relative;
    min-height: 24px;
    padding-left: 36px;
    display: flex;
    align-items: center;
}

.filter-option ul li:last-child {
    margin-bottom: 0
}

.filter-option h5 {
    letter-spacing: 0.005em;
    font-size: 20px;
    text-transform: inherit;
}

.clearall {
    
}

.filter-option ul li input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 0;
}

.filter-option ul li label {
    margin-left: 0 !important;
    margin-bottom: 0
}

.home-collaboration {
    background: #43437a;
    padding: 70px 0 70px;
    position: relative;
    width: 100%;
    clear: both;
    margin: 0 0 50px 0;
    text-align: center;
}

.home-collaboration .container > h4 {
    
    font-size: 30px;
    color: #FFFFFF;
    text-transform: inherit;
}

.collaboration-logo ul {
    padding: 10px 0 0 0;
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.collaboration-logo ul li {
    margin: 0 5px 10px 5px
}

.collaboration-logo ul li a {
    overflow: hidden;
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 50%;
}
.collaboration-logo ul li a img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.home-collaboration .featuredpro-section .container {
    padding: 20px 0 0 0
}

.home-newly {
    padding: 40px 0 40px;
}

.opened-inner {
    background: linear-gradient(180deg, #0483A4 0%, #1CA9CD 100%);
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border-radius: 16px;
    padding: 15px;
    height: 100%;
}

.open-body ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.open-body ul li a {
    display: flex;
   flex-flow: column wrap
}

.open-body ul li:last-child a {
    border-bottom: 0;
    padding: 0;
    margin-bottom: 0;
}

.ob-image {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 62.25%;
    border-radius: 20px;
    overflow: hidden;
}
.ob-image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ob-header-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ob-header-image {
    width: 60px;
    height: 60px;
    overflow: hidden;
}
.ob-content {
    width: 100%;
    font-size: 14px;
    padding: 10px 0 0 0;
    text-align: center;
    color: #000
}
.ob-content p{
    color: #000;
    margin: 0;
    
    font-weight: 500;
    font-size: 14px;
}

.ob-content h5 {
    font-size: 18px;
    margin: 0 0 10px 0;
}

.ob-content span {
    color: #000;
    
    font-size: 18px;
}

.open-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 0 14px 0;
    align-items: center
}

.ob-header-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: calc(100% - 65px);
}

.ob-header-content h5 {
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #000;
    
}

.ob-header-content span {
    display: block;
    line-height: 1;
    font-size: 14px;
    color: #000;
}


.opened-carsouel {
    padding: 5px 0 0 0;
}

.opened-carsouel .owl-stage {
    padding: 5px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
}

.opened-carsouel .owl-stage .owl-item > div {
    height: 100%;
}

.header-login-area ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
}

.header-login-area {
    padding: 15px 0 5px;
}
.logo img {
    max-height: 85px;
}
.header-login-area ul li {
    font-size: 14px;
    
    position: relative;
}

.header-login-area ul li a {
    display: inline-flex;
    align-items: center;
    color: #121212;
}

.header-login-area ul li a img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
}

.header-login-area ul li.hla-login {
    margin-right: 15px;
}

.header-login-area ul li a:hover {
    color: #944FCA;
}

.header-top-cls {
    padding: 10px 0 10px 0;
}

.logo-section {
    display: flex;
    align-items: center;
}

.header-info {
    display: flex;
    flex-flow: column wrap;
    position: relative;
    padding-left: 26px;
}

.header-info span {
    font-size: 14px;
    line-height: 1;
    display: block;
    margin: 4px 0 0 0;
}

.header-info a {
    
    color: #121212;
    display: flex;
    align-items: center;
}

.header-info .map-loc {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: 20px;
}

.header-info a img {
    margin-left: 10px;
}

.logo {
    margin-right: 30px;
}

.search-top {
    position: relative;
    cursor: pointer;
    width: 295px;
    margin-right: 20px;
}

/* .search-glass {
    position: absolute;
    left: 15px;
    top: 11px;
    width: 18px;
} */
/* .htico_search .search-top > img {
    width: 26px;
    position: relative;
    top: 1px;
}
.htico_search {
    margin: 0 0 0 15px;
} */
.open-product-details {
    overflow-y: hidden !important;
}

div#ProductDetailMdl {
    background: #fff;
    width: 680px;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    z-index: 991;
    /* border-radius: 40px 0 0 40px; */
    overflow: hidden
}

.open-product-details div#ProductDetailMdl {
    transform: translateX(0);
}

.inn-product-popup {
    width: 100%;
    transform: none;
    margin: 0;
}

.restaurant-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.restaurant-main:before,
.restaurant-main:after {
    display: none
}

.restaurant-details-lhs {
    width: 280px;
    position: sticky;
    top: 120px;
}

.restaurant-details-rhs {
    /* width: calc(100% - 320px); */
    width: 100%;
}

ul.product-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    
}
.menu-section-inner-scroll {
    height: 450px;
    overflow: hidden;
}

ul.product-menu li {
    margin: 0 0 20px 0;
}

.working-info-parent h3{ margin: 0; font-size: 18px; color: #fff }
.working-info-parent h3 span{ font-weight: normal; font-size: 15px; margin-left: 10px}
.working-hr strong{ font-weight: normal; font-size: 13px;}
.working-info-parent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #282728;
    padding: 10px 15px 10px 30px;
    border-radius: 50px;
    color: #fff;
    align-items: center;
}
.abs-img {
    position: absolute;
    left: 0;
    top: 1px;
    max-width: 20px;
}

.working-hr span {
    display: block;
}

.owl-nav {
    position: absolute;
    right: 0;
    top: -45px;
    display: flex;
    line-height: 1;
}

.owl-nav > div {
    width: 30px;
    height: 30px;
    overflow: hidden;
    font-size: 0;
}

.owl-nav > div:after {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    content: "";
    background: url(../images/slider-arrow.png) no-repeat top;
    background-size: contain;
    
}
.owl-nav .owl-prev:after {
    transform: rotate(180deg);
}
.category-product-list .owl-nav > div:after{ filter: invert(1)}


.promo-group-slider .owl-nav .owl-next:after {
    
}

.owl-nav .owl-next:after {
    right: 0;
}

.owl-nav .owl-prev:after {
    left: 0;
    
}

.owl-nav .owl-next {
    margin-left: 7px
}

.promo-home {
    margin: 50px 0 70px 0;
}
.promo-banner .owl-stage {
    display: flex;
}

.promo-home .owl-stage .owl-item > div {
    height: 100%;
}

.promo-home .owl-stage .owl-item > div a {
    height: 100%;
    display: block;
}

.promo-home .owl-stage .owl-item > div a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.small-address ul {
    padding: 0;
    list-style: none;
}

.small-address ul li {
    margin: 16px 0;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 16px;

}

.small-address ul li a {
    color: #121212;
    position: relative;
    padding-left: 36px;
    min-height: 24px;
    display: block
}

.small-address ul li a:before {
    width: 24px;
    height: 24px;
    border: 2px solid #787878;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
}

.small-address ul li a:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: 6px;
    top: 6px;
    content: "";
    background: #fff
}

.small-address ul li a.active:before {
    border-color: #7e33ad;
}

.small-address ul li a.active:after {
    background: #1c1c40
}

.whyus-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


.home-banner .owl-carousel {
    margin-bottom: 40px;
}
.owl-dots {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    bottom: 0px;
}

.owl-dot {
    width: 11px;
    height: 11px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 3px;
    background: #fff;
}
.owl-dot.active{
    border:1px solid #fff;
    background: #3a5da2;
}
.home-banner .owl-dots{  bottom: 30px; }

.innerproduct > h3 {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 24px;
}

.products-list-ulmain.horizontal-view {
    justify-content: space-between
}

.products-list-ulmain.horizontal-view > li {
    width: 43.51464435146444%;
    margin: 0 0 46px 0;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.products-list-ulmain.horizontal-view > li .product-info-div,
.free-gift .product-info-div {
    padding: 0 30px 0 0;
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 92px);
    position: relative;
}

.products-list-ulmain.horizontal-view > li .product-price h3 {
    font-size: 14px;
    line-height: 18px;
    color: #454545;
}

.products-list-ulmain.horizontal-view > li .products-image-div,
.gift-products-image-div {
    height: 80px;
    width: 80px;
    border-radius: 12px;
    overflow: hidden;
}

.products-list-ulmain.horizontal-view > li .products-ordernow-action,
.free-gift .products-ordernow-action {
    bottom: inherit;
    top: 0;
    right: 0;
    left: inherit;
}

.products-list-ulmain.horizontal-view .product-price {
    display: flex;
    align-items: center;
}

.products-list-ulmain.horizontal-view .product-price h4.product-originale-price {
    margin: 0 0 0 12px;
}

img.restaurant-logo {
    display: none;
}

.restaurant-info-lhs {
    padding: 0px 0 30px 0;
}
.first-list{
    background: #eef4ff;
    padding: 30px 20px 20px;
    border-radius: 20px;
}
.category-product-list {
    margin: 0 0 40px 0;
}
.first-list .innerproduct > h6{ margin-bottom: 20px; padding-right: 80px; }

.inn-product-img-bg.prodet_top .slick-track,
.inn-product-img-bg.prodet_top .slick-track .slick-slide {
    display: block !important;
}

.inn-product-img-bg.prodet_top img {
    width: 100%; border-radius: 0 0 0 40px;
}

.prodet_baninfo {
    padding: 24px 40px 0 40px;
    position: relative
}

.cart-outlet-name a img,
.outlet-name a img {
    width: 6px;
    height: 8px;
    position: absolute;
    right: 12px;
    top: 8px;
}

.edit-pro-left {
    text-decoration-line: underline;
    font-size: 12px;
    color: #454545;
}

.cart_row-header h6 {
    
}

.changi-reward {
    background: #F7F7F7;
    border-radius: 12px;
    padding: 12px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
}

.changi-reward p {
    margin: 0;
}

.changi-reward span {
    
}

.changi-reward span img {
    width: 18px;
    margin: 4px 4px 0 0;
}

.reward-total {
    padding: 5px 0 10px 0;

}

.sidebar-discount {
    padding: 24px 0;
    border-top: 1px solid #e4e4e4;
    margin-top: 20px;
}

.sidebar-discount-lhs a,
.sidebar-discount-lhs p {
    font-size: 12px;
}

.sidebar-discount-lhs span {
    text-transform: uppercase;
    margin-right: 8px;
}

.sidebar-discount-inner {
    margin: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
}

.sidebar-promo-add a {
    color: #f9a619;
    
}

.free-delivery-blue {
    background: #0057B8;
    color: #fff;
    padding: 16px 24px;
    position: relative;
    z-index: 1;
}

.free-delivery-blue p {
    margin-bottom: 0;
    
}

.free-delivery-blue-text span {
    opacity: 0.8;
}

.free-delivery-blue-text a {
    color: #fff;
    opacity: 0.8;
}

.free-delivery-blue-text {
    width: calc(100% - 48px);
}

.free-delivery-blue-img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.free-gift-top {
    padding: 20px 12px;
}

.free-gift {
    background: #F9F0FF;
    border-radius: 16px;
    padding: 12px;
    display: flex;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    color: #1c1c40;
    font-size: 14px;
}

input.cart-no-stock-qty {
    color: #CC1000;
}

.qty_bx span.qty_plus.disabled:before,
.qty_bx span.qty_plus.disabled:after {
    background: #ABABAB;
}

.no-longer-cart-item {
    position: relative;
    background: #FFE3E0;
    padding: 16px 16px 16px 64px;
    color: #CC1000;
}

.no-longer-cart-item img {
    position: absolute;
    left: 26px;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.no-longer-cart-item a {
    
    color: #cc1000;
    text-decoration: underline;
    display: inline-block;
}

.sticky-total {
    line-height: 1;
    font-size: 16px;
    
    width: 47%;
}

.sticky-total span {
    display: block;
    margin-bottom: 10px
}

.sticky-total span:last-child {
    margin-bottom: 0;
}

.sticky-total-button {
    width: 100%;
}

.sticky-total-button .button {
    width: 100%;
}

.free-gift-title h5 {
    color: #1c1c40;
    
    font-size: 16px;
    margin-bottom: 3px;
}

.free-gift-description p {
    margin-bottom: 0;
}

.free-gift-description span {
    
    text-transform: uppercase;
    margin-top: 2px;
    display: block
}

#warning-not-avail-sku-popup {
    margin: 50px auto;
}

.button-full .button {
    width: 100%;
}

.unavailable-alert-body {
    padding: 0;
}

.unavailable-alert-inner {
    padding: 60px 24px 50px;
    position: relative;
}

.unavailable-alert-inner .button {
    margin-bottom: 0;
    margin-top: 10px;
    text-transform: inherit
}

.unavailable-alert-inner h4 {
    
}

.unavailable-list {
    padding: 24px;
    border-top: 7px solid #e4e4e4;
    text-align: left;
}

.unavailable-list h5 {
    
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #CC1000;
}

.unavailable-list .product-details:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.unavailable-list .product-details .row-replace .cart_left {
    padding-left: 0;
}

.unavailable-list .product-details .row-replace .cart_right .qty_bx {
    padding: 0;
    background: #E5E5E5;
    border-radius: 99px;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: #454545;
    line-height: 24px;
    text-align: center;
    font-weight: 900
}

.unavailable-overflow {
    max-height: 200px;
    overflow-y: auto;
}

.purple-round {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #1c1c40;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -35px;
}

.order_popup.small-popup .mfp-close {
    top: 41px;
}

.products-single-li.no-stock-product .products-image-div img, .products-single-li.no-stock-product .product-info-div {
    opacity: 0.5;
}
.not-available-stock {
    padding: 5px 8px;
    position: absolute;
    max-width: 130px;
    left: 0;
    bottom: 13px;
    background: rgba(18, 18, 18, 0.9);
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    font-size: 11px;
    margin: 0 auto;
    right: 0;
    width: 100%;
    line-height: 14px;
}
.edit-alert-body {
    text-align: center;
    padding: 40px 25px;
}

.edit-alert-body p {
    padding: 0 10px
}

.edit-alert-body .button {
    margin: 10px 0px 15px 0px;
}

.edit-alert-body h4 {
    
    margin-bottom: 20px;
}

.edit-warning-popup .mfp-close {
    top: 23px;
}

/* .checkout-heading {
    margin-bottom: 24px;
} */

.checkout-heading h5{
    margin-bottom: 0;
    font-size: 20px;
    
}

p.track-text {
    margin: 0;
    color: #787878;
    font-size: 14px;
    padding-bottom: 10px;
}

.checkout-billing-address .fg-with-link {
    margin-bottom: 0
}

.fg-with-helptext .form-control,
.fg-with-link .form-control {
    margin-bottom: 5px;
}

.fg-with-link a {
    
    font-size: 14px;
}

.chk-payment-col-radio {
    padding: 0;
    margin: 0;
    list-style: none;
}

.chk-payment-col-radio li {
    border: 1px solid #e5e5e5;
    border-radius: 9px;
    padding: 20px 20px 20px 45px;
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    position: relative;
    
}
.chk-payment-col-radio li input[type=radio] {
    position: absolute;
    left: 15px;
    top: 33px;
}
.cdd-details-rhs .cart_footer,
.tnk_cart_footer {
    padding-left: 0;
    padding-right: 0;
    /* border-top: 1px solid #E5E5E5; */
}

.switch-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switch-header a {
    font-size: 14px;
   color: #f9a619;
    
}

.checkout-changi-login {
    background: #F7F7F7;
    border-radius: 12px;
    margin: 0 0 24px 0;
    padding: 12px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
}

.checkout-changi-login p {
    margin: 0;
    display: flex;
    align-items: center;
}

.checkout-changi-login p img {
    margin-right: 10px;
}


.cart_row-full .cart_row {
    line-height: 1;
    font-size: 15px;
    text-transform: uppercase
}

.cart-view-cart {
    padding: 0 0 24px 0;
}

.checkout-cart-open {
    position: relative;
    display: block;
    padding-right: 20px;
    
    color: #121212;
}

.checkout-cart-open:after {
    position: absolute;
    right: 5px;
    top: 50%;
    width: 12px;
    height: 8px;
    background: url(../images/arrow-down.svg) no-repeat;
    content: "";
    margin-top: -4px;
}

.checkout-btn-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 55px 0 0 0;
}



.chk-payment-btn-row .button {
    margin: 0;
    width: 100%;
    text-transform: inherit;
    background: #3a5da2;
    color: #fff; font-size: 17px;
}
.chk-payment-btn-row .button:hover{
     background: #1c1c40;
    color: #fff;
}
.checkout-btn-total .checkout-total {
    
    font-weight: bold;
}



.thy {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1148px;
    padding: 60px 0 30px 0;
}

.tnk-chk-lhs {
    width: 55.05226480836237%
}

.tnk-chk-rhs {
    width: 32.05574912891986%;
    margin-left: 12.89198606271777%;
}

.delivery_total {
    padding: 24px 44px;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border-radius: 16px;
    text-align: center;
    margin: 24px 0;
}
.tnkyou-maindiv .delivery_total {
    padding: 0 20px;
    background: none;
    box-shadow: none;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 30px;
}

.tnk-chk-lhs .hcart_tt {
    background: #fff;
    display: block;
    padding: 0;
    color: #121212;
    height: auto;
    margin: 0 0 18px 0
}

.tnk-chk-lhs .hcart_tt h5 {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    
    margin: 0
}

.tnk-chk-order .button {
    margin: 0
}

.thank-product-img {
    background: #C4C4C4;
    height: 132px;
    margin: 0 0 24px 0;
}

.delivery_total h6 {
    font-size: 12px;
    color: #1c1c40;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    
    margin: 0 0 5px 0;
}

p.checkoutDate {
    letter-spacing: 0.006em;
    font-size: 24px;
    
    margin-bottom: 22px;
}

.outlet-product-parent .row:before,
.outlet-product-parent .row:after {
    display: none
}

.outlet-product-parent {
    padding: 0px 0 16px 0;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
}

.outlet-product-inner:last-child .outlet-product-parent {
    padding: 0px;
    border-bottom: 0;
    margin-bottom: 0;
}
.tnk-delivery .outlet-product-inner .outlet-product-parent{
    padding: 0px 0 16px 0;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
}
.tnk-delivery .outlet-product-inner .outlet-product-parent:last-child{
    border-bottom: 0;
    padding-bottom: 0
}

.mainacc_toptext.tick h5 {
    font-size: 28px;
    display: flex;
    align-items: center;
}

.mainacc_toptext.tick h5 img {
    margin-right: 16px;
}

.mainacc_toptext.tick h4 {
    text-transform: uppercase;
    letter-spacing: 0.06em;
    margin-bottom: 24px;
    font-weight: 900
}

/* .products-ordernow-action .button > div {
    display: none
} */

.datetime_inner_lbltext h4,
.time_inner_lbltext h4 {
    font-size: 20px;
    letter-spacing: 0.005em;
    margin-bottom: 20px
}

.datetime_inner_lbltext h4 {
    padding-top: 5px;
}

.time_inner_lbltext {
    padding-top: 30px
}

ul.order_ordstdtime_mainul {
    padding: 0;
    list-style: none;
    height: 267px;
    overflow-y: hidden;
    transition: 0.4s linear all;
    max-height: 267px;
}
ul.order_ordstdtime_mainul.timeslot-heightextended{
     height: 100%;
     max-height: 267px;
    overflow-y: auto;
} 

ul.order_ordstdtime_mainul li {
    border-bottom: 1px solid #e5e5e5;
    padding: 14px 0;
    line-height: 1;
    display: flex;
    align-items: center;
}
ul.order_ordstdtime_mainul li.noslot_found_cls{ border-bottom: 0 } 

ul.order_ordstdtime_mainul li input[type=radio] {
    margin-right: 12px;
}
ul.order_ordstdtime_mainul li label{ margin: 0; font-weight: normal;}

.tnk-chk-rhs .grant-total-cls .row-replace {
    display: flex;
    justify-content: space-between;
}

.tnk-chk-rhs .grant-total-cls p, .mobile-payment-summary  .grant-total-cls p {
    margin: 0
}

.tnk-chk-rhs .grant-total-cls {
    padding-bottom: 22px
}

.tnk-chk-rhs .grant-total-cls, .mobile-payment-summary  .grant-total-cls, .Viewreceipt .grant-total-cls{
    
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #121212;
}
.Viewreceipt .grant-total-cls span{font-size: 20px}

.tnk-chk-rhs > h4 {
    font-size: 18px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.checkout-header {
    position: sticky;
    top: 0;
    z-index: 99;
    margin: auto;
    background: #fff;
    padding: 25px 0;
}

.checkout-header .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.ch-links ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.ch-links ul .htico_cart {
    padding-left: 0;
    margin-left: 0;
}

.ch-links ul .htico_cart .hcartdd_trigger div {
    display: flex;
    align-items: center;
}

.ch-links > ul > li {
    margin-right: 40px;
    
}

.ch-links > ul > li:last-child {
    margin-right: 0
}

.ch-links ul .htico_cart .hcartdd_trigger div img {
    margin-right: 5px;
}

body.open-product-details:after {
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.68);
    z-index: 99;
}

.components_selct .custom_radio {
    padding: 15px 36px 15px 0px;
    display: flex;
    align-items: center;
    position: relative;
}

.components_selct .custom_radio input[type=radio] {
    position: absolute;
    right: 0;
    top: 15px;
}
.compo_acc_innerdiv{
    padding: 0 20px;
}
.promo-image {
    margin-bottom: 40px;
}

.promo-image img {
    width: 100%;
}

.promo-title {
    
    font-size: 28px;
    margin-bottom: 10px;
}

.promo-period {
    
}

.promo-desc {
    padding: 20px 0 0 0;
}

.small-popup .form-group {
    margin-bottom: 24px
}

.header-with-icon {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: 0 0 24px 0;
}

.header-with-icon h4 {
    margin: 0;
}

.left-back-arrow {
    position: absolute;
    left: 0;
    top: 2px;
    cursor: pointer;
}


.promo-detail-top-div .container,
.promo-apply-btn .container {
    max-width: 958px;
    margin: 0 auto;
}

.promo-apply-btn .button {
    min-width: 280px;
    color: #FCFCFC;
    text-transform: inherit
}

.promo-group-list {
    margin: 50px 0 20px 0;
}

.promo-group-slider {
    padding: 24px 0;
}

.promo-group-slider .container > h3,
.promo-group-normal .container > h3 {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
    color: #fff;
}

.promo-group-normal {
    padding: 50px 0 40px 0;
}

.promo-group-normal .container > h3 {
    color: #000;
    padding-bottom: 10px
}

.promo-group-normal .product-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.promo-group-normal .product-list ul li {
    width: 18.5%;
    margin: 0 1.875% 30px 0;
    height: auto;
}

.promo-group-normal .product-list ul li:nth-child(5n) {
    margin-right: 0;
}

.search-result {
    padding: 60px 0 60px;
}

.search-popular-cuisines ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.search-popular-cuisines ul li {
    margin: 0 12px 12px 0;
}

.search-popular-cuisines ul li a {
    border: 1px solid #D1D1D1;
    padding: 6px 15px;
    line-height: 1;
    color: #454545;
    border-radius: 29px;
    display: block;
    transition: 0.3s linear all;
}

.search-popular-cuisines {
    padding: 30px 0 20px 0;
}

.search-popular-cuisines ul li a:hover {
    border-color: #1c1c40;
    background: #1c1c40;
    color: #fff;
}

.search-result h4 {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 25px;
}

.search-resturent {
    margin-bottom: 50px
}

.search-dishes > ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none
}

.search-dishes > ul > li {
    width: 15.625%;
    margin: 0 1.25% 32px 0;
    height: auto;
}

.search-dishes > ul > li:nth-child(6n) {
    margin-right: 0
}

.ribbon {
    background: #0057B8;
    border-radius: 4px;
    display: inline-flex;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1;
    padding: 5px 8px;
    
    position: absolute;
    z-index: 1;
    left: 8px;
    top: 8px;
}

.restaurant-error label {
    background: #FFE3E0;
    border-radius: 8px;
    padding: 12px 12px 12px 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #CC1000;
    position: relative;
}

.restaurant-error label:before {
    width: 20px;
    height: 20px;
    left: 14px;
    position: absolute;
    top: 50%;
    background: url(../images/info-red.svg) no-repeat;
    content: "";
    transform: translateY(-50%);
}

.restaurant-error {
    padding: 0 0 20px 0
}

.restaurant-error p {
    margin: 0;
}

.restaurant-error p a {
   
}

.mix-match-rhs .owl-stage {
    display: flex;
}

.inputerror-msg-cls {
    font-size: 14px;
    color: #CC1000;
    padding: 0 0 0 30px;
    line-height: 1;
    position: relative;
    margin: 8px 0 0 0;
}

.inputerror-topdiv .inputerror-msg-cls:before {
    width: 14px;
    height: 14px;
    left: 8px;
    position: absolute;
    top: 0px;
    background: url(../images/info-red.svg) no-repeat;
    content: "";
    background-size: contain;
}

.inputerror-topdiv > label {
    color: #CC1000;
}

.inputerror-topdiv input {
    border-color: #CC1000
}

.promo-home-header {
    margin-bottom: 15px;
}

.promo-home-header h4{
    margin-bottom: 10px;
    font-weight: bold;
}

.mobile-location {
    padding: 10px 0;
    display: none;
}

.mobile-location .header-info a {
    margin-bottom: 0;
}

.search-no-parent {
    padding: 40px 0 0 162px;
    position: relative;
}

.bigweight {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-size: 24px;
}

.search-no-found {
    padding-left: 192px;
    position: relative;
    min-height: 165px;
}

.search-no-found img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 170px;
}

.mobile-outlet-filter {
    display: none
}

.popup-filter {
    position: fixed;
    top: 50px;
    left: 0;
    background: #ffffff;
    z-index: 991;
    padding: 0;
    transform: translateY(100%);
    transition: 0.3s linear all;
    right: 0;
    border-radius: 15px 15px 0 0;
    overflow-y: auto;
    bottom: 0;
}

.popup-filter.active {
    transform: translateY(0%);
}

.button-bottom {
    background: #FCFCFC;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    padding: 24px;
    position: sticky;
    bottom: 0;
    z-index: 1;
}

.button-bottom a {
    width: 48%;
    margin: 0;
}

.popup-filter .filter-option {
    border-bottom: 8px solid #e5e5e5;
    padding: 24px;
    margin: 0;
}

.popup-filter .filter-option ul li {
    border-bottom: 1px solid #E5E5E5;
    padding: 4px 0 25px 36px;
    margin-bottom: 20px;
    min-height: inherit;
}

.popup-filter .filter-option ul li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.mobile-outlet-filter .filter-list {
    display: flex;
    list-style: none;
    padding: 0;
    white-space: nowrap;
    overflow-x: auto;
}

.mobile-outlet-filter .filter-list li {
    margin: 0 12px 12px 0;
}

.mobile-outlet-filter .filter-list li a {
    border: 1px solid #D1D1D1;
    padding: 6px 15px;
    line-height: 1;
    color: #454545;
    border-radius: 29px;
    display: block;
    transition: 0.3s linear all;
}

.mobile-outlet-filter .filter-list li a:hover,
.mobile-outlet-filter .filter-list li.active a {
    border-color: #1c1c40;
    background: #1c1c40;
    color: #fff;
}

.mobile-outlet-filter .ms-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
}

span.filter-icon {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.product_chosen_hea.compopro_acc_head {
display: flex;
    justify-content: space-between;
    align-items: center;
    background: #576AAB;
    padding: 16px 30px;
    border-radius: 40px;
}

.min-max-selectiontext {
    width: 150px;
    text-align: right;
    /* color: #FFFFFF; */
    font-size: 14px;
    line-height: 1;
}

/* .min-max-disable { color: #fff; } */

.qty_bx span.disable-act-icon {
    border-color: #D1D1D1
}

.qty_bx span.disable-act-icon.qty_plus:before,
.qty_bx span.disable-act-icon:after {
    background: #D1D1D1
}

.promo-home-header a {
    display: none;
}

.promo-group-slider .owl-stage {
    display: flex;
}


.promo-desc ol li {
    margin-bottom: 20px
}

.mainacc_menuout {
    width: 280px;
    margin: 0;
}

.mainacc_menucontent {
    width: calc( 100% - 380px );
    padding: 0;
}

.mainacc_menusec .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.mainacc_menusec .container:before, .mainacc_menusec .container:after{ display: none}

.mainacc_menuout h3 {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
    padding-bottom: 20px;
}

.mainacc_menulist {
    padding: 10px 0 50px 0;
    list-style: none;
}

.mainacc_menulist li {

    border-bottom: 1px solid #e5e5e5;
    padding: 18px 0 18px 0;
}

.mainacc_menulist li a {
    display: flex;
    color: #121212;
    
    align-items: center;
    position: relative;
    min-height: 24px;
    transition: 0.3s linear all;
}

.mainacc_menuout .ghost-button {
    padding: 7px 14px;
}
.mainacc_menulist li a i {
    font-size: 18px;
    margin: 0 20px 0 0;
}
.mainacc_menulist li a:hover,
.mainacc_menulist li.active a{
    color: #3a5da2;
}

.mainacc_menucontent > h4,
.current_order > h4,
.past-order > h4 {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 18px;
}

.past-order {
    padding: 50px 0 0 0;
}

.past-order .myacc_order_details ul li {
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 0 16px 0;
}

.mobile-terms {
    display: none;
}

.desktop-terms,
.mobile-terms {
    margin: 25px 0 0 0;
}

.desktop-terms a,
.mobile-terms a {
    
}
body .accordion{
    border: 0;
    border-radius: 0;
}
body .accordion .accordion-item .panel {
    border: 0;
    margin: 0;
    box-shadow: none;
}
body .accordion .accordion-item .title{
    color: #121212;
    padding: 10px 30px 10px 0;
}
.Collapsible__trigger {
    color: #121212;
    padding: 26px 40px 26px 0;
    background: #fff;
    line-height: 20px;
        font-weight: 700;
    font-size: 16px;
    position: relative;
    display: block;
}
.Collapsible{
    border-bottom: 1px solid #e5e5e5;
}
.Collapsible__trigger:after{
    content: "";
    position: absolute;
    right: 6px;
    top: 30px;
    background: url(../images/arrow-down.svg) no-repeat;
    width: 16px;
    height: 12px;
    background-size: contain;
    margin: 0;
    transition: 400ms linear all;
}
.Collapsible__trigger.is-open:after{
    content: "";
    transform: rotate(180deg)
}
.leveltwo-inner-combo .product_chosen_hea.compopro_acc_head {
    margin-bottom:15px
}

.leveltwo-inner-combo .product_chosen_item_left.product_chosen_item_left_full {
    margin: 0 0 20px 0;
    padding: 0;
    border: 0
}

.productlist_rowtopdiv:last-child .custom_radio {
    border-bottom: 0;
}

.leveltwo_comboset_individual {
    background: #FCFCFC;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
    padding: 24px;
    margin: 20px 0
}

.leveltwo-inner-combo .product_chosen_hea.compopro_acc_head h6 {
    font-size: 16px;
    text-transform: inherit;
    letter-spacing: 0;
}

.show-leveltwo-comboact {
    color: #1c1c40;
    
    cursor: pointer;
}

.show-leveltwo-comboact:hover {
    color: #121212
}

.leveltwo_comboset_individual .components_selct .custom_radio:first-child {
    padding-top: 0
}
.leveltwo_comboset_individual .components_selct .custom_radio:first-child input[type=radio]{
    top: 0;
}

.leveltwo-individualcombo-confirm button {
    margin-bottom: 0;
    background: #fff;
    color: #8a2aa2;
    border: 2px solid #8a2aa2;
    padding: 12px 30px 12px;
}
.leveltwo-individualcombo-confirm button:hover{
    background: #8a2aa2;
    color: #fff;
}
.components_selct .productlist_rowtopdiv {
    border-bottom: 1px solid #E5E5E5;
}
.components_selct .productlist_rowtopdiv:last-child{
    border-bottom: 0
}
.leveltwo-combodetail-maindiv {
    margin: 15px 0 0 0;
}
.leveltwo_comboset_individual .components_selct .custom_radio{
     border-bottom: 1px solid #E5E5E5;
}
.leveltwo-inner-combo .product_chosen_item_left.product_chosen_item_left_full:last-child{
    margin-bottom: 0;
}
.leveltwo-individualcombo-confirm {
    text-align: right;
}
.combo-checkbox-maindivrow{
    justify-content: flex-start;
    position: relative;
    min-height: 24px;
}
.combo-checkbox-maindivrow .chosen_adn_left{ width: auto; padding-left: 36px; position: relative}
.combo-checkbox-maindivrow .combo-checkbox-cls{ position: absolute; left: 0; top: 0;z-index: 1}

.combo-checkbox-maindivrow .css-label-chkbox{ 
 margin: 0
}
.cart_add_div{width: 100%;}
.cart_add_div button{display: block; width: 100%; margin: 0; text-transform: inherit;}
.open-product-details #scrollbutton{ display: none !important;}
.mobile-payment-summary, .track-txt, .order_item_mobile, .hcart_tt_mobile{display: none}
.tnk-delivery .cart_extrainfo{    line-height: 1.5;}
.tnk-delivery span.order-qty {
    font-size: 12px;
    
    min-width: 24px;
    min-height: 24px;
    background: #e5e5e5;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #454545;
    margin: 0 0 5px 0;
}
.thankyou-footer {
    margin: 20px 0 0 0;
    font-weight: 500;
}
.cartp{
    display: none;
}
.page-faq .cms-content h4 {
    padding-top: 50px;
}
.page-faq .cms-content h4:first-child {
    padding-top: 0px;
}
.popup-filter-header{
    position: sticky;
    text-align: center;
    padding: 30px 30px 20px;
    top: 0;
    background: #fff;
    z-index: 1;
}

.popup-filter-header h3{
    font-size: 23px;
    letter-spacing: 0.006em;
    margin: 0;
    position: relative;
}
.popup-filter-header h3 a {
    position: absolute;
    right: 0;
    top: 7px;
}
.search-mobile-home-inner{
    position: relative;
}
.search-mobile-icon{
    position: absolute;
    right: 12px;
    top: 13px;
}
.search-mobile-home-inner input {
    margin-bottom: 0;
    padding-right: 40px;
}
.search-mobile-home{
    display: none;
}
.open-filter {
    overflow: hidden !important;
}

.open-filter:after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #606060;
    z-index: 99;
}
.promoadd-buttons .button {
    margin: 0 0 0 5px;
    font-size: 13px;
    padding: 8px 20px;
}

.promoadd-buttons {
    display: flex;
    justify-content: flex-end;
}
.promoadd-buttons .button:first-child{order: 2}
.promoadd-buttons .button:last-child{order: 1}


.order-details {
    width: 66.25%;
    padding: 0 220px 0 0;
}

.delivery_total_number {
    width: 50%;
}

.delivery_total_text {
    width: 50%;
}

.delivery_total_left h2 {
    text-align: left;
    font-size: 18px;
    margin: 0 0 8px 0;
    font-weight: 700;
}

.delivery_total_left.delivery_total_right h2 {
    text-align: right;
}
.delivery_total_left.delivery_total_right h4 {
    text-align: right;
}

.delivery_total_left h4, .delivery_total_text span {
    font-size: 16px;
    color: #454545;
    font-weight: normal;
    line-height: 20px;
    margin: 0;
}
.order-date-parent {
    display: flex;
    justify-content: space-between;
        padding: 40px 0;
}
.tnk-delivery .cart_extrainfo{
    font-size: 14px;
    margin: 0 0 1px 0;
}
.hcart_tt-details h5, .tnk_cart_footer h4{ font-size: 18px; text-transform: uppercase; letter-spacing: 0.06em; }
.cart_footer.tnk_cart_footer{ border: 0; }
.order-details .cart_footer.tnk_cart_footer{ padding: 40px 0 0 0 }
.payment-via {
    display: block;
    font-size: 12px;
    color: #454545;
    font-weight: normal;
    margin-bottom: 0
}

.delivery_total_left img{ position: absolute; left: 0; top: 0; }
.search-mobile-inner {
    position: relative;
    display: none;
}

.search-mobile-inner input[type="text"] {
    margin: 0;
}
.outlet-recommended h4 {
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-size: 18px;
}

.outlet-recommended .container > p {
    font-size: 14px;
    line-height: 18px;
}

.outlet-recommended ul {
    padding: 24px 0 0 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: space-between;
}

.outlet-recommended ul li {
    width: 49%;
    position: relative;
    margin: 0 0 24px 0;
}

.outlet-recommended ul li .outlet-content {
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border-radius: 16px;
        width: 100%;
    padding: 52px 12px 12px 12px;
}
.outlet-recommended ul li .outlet-img {
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    max-height: 80px;
    width: 100%;
        width: calc( 100% - 24px );
}
.outlet-recommended ul li .outlet-img img{ width: 100%; height: 100%; object-fit: cover; }

.outlet-recommended ul li a {
    display: block;
    position: relative;
    padding-top: 40px;
}
.mobile-top-footer ul {
    padding: 0;
    list-style: none;
    display: flex;
}

.mobile-top-footer ul li {
    display: inline-block;
    margin: 0 5px 0 5px;
}
.mobile-top-footer ul li:first-child{
    margin-left: 0
}
.mobile-top-footer ul li a{
    
    color: #000;
}
.mobile-top-footer .button{
    padding: 8px 16px;
    font-size: 14px;
    margin: 30px 0 15px 0;
}

.hide-title{display: none;}
.outlet-recommended{display: none}
.product-mobile-menu .menu-title-link{ color: #454545;  position: relative;padding-bottom: 2px;
    display: block;}
/* .product-mobile-menu .menu-title-link:before{
         position: absolute;
    width: 100%;
    height: 2px;
    background:#1c1c40;
    background: linear-gradient(281.74deg, #8A2AA2 17.2%, #1c1c40 85.13%);
    top: 100%;
    left: 0;
    pointer-events: none;
        content: '';
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
} */
.product-mobile-menu .menu-title-link:hover:before, .product-mobile-menu .menu-title-link.active:before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}
.product-mobile-menu .menu-title-link:hover , .product-mobile-menu .menu-title-link.active{ color: #1c1c40;   } 
img.scanqr {
    width: 150px;
}
.restaurant-image-mobile{
    position: relative;
    border-radius: 30px;
    margin: 0 0 25px 0;
    overflow: hidden;
}
.restaurant-image-mobile img:first-child{ width: 100%;}
img.restaurant-logo {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    right: 0;
    top: 0;
    margin: auto;
}
.checkout-changi-login span{margin: 0}
.mobile-menu.without-login.active .mobile-menu-body {
    justify-content: center;
    padding: 60px 24px 60px;
    position: relative;
}
.mobile-login-header h2{font-size: 24px;letter-spacing: 0.006em; }
.full-width {
    width: 100%;
    text-align: center;
    display: block;
    
}
.center-btn a{margin: 0;}
.center-btn{margin: 30px 0 20px 0;}
.without-login .mobile-top-footer {
    position: absolute;
    bottom: 40px;
    left: 24px;
}
.mobile-top-footer ul li a:hover{
    color: #1c1c40;
}
.search-outlet-popup .search-product{ padding: 0  }
.search-product-listing .spm-lhs{width: 100%}

.search-outlet-popup .search-result-mobile li{
    width: 100%;
    margin: 0 0 16px 0;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 16px;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.search-outlet-popup .search-result-mobile li .products-image-div{
    height: 80px;
    width: 80px;
    border-radius: 12px;
    overflow: hidden;
}
.search-outlet-popup .search-result-mobile li .product-info-div{
    padding: 0;
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 92px);
    position: relative;
    text-align: left
}
.search-outlet-popup .search-result-mobile {
    margin: 20px 0 0 0;
    max-height: 350px;
    overflow-y: auto;
    padding: 0;
}
.mainacc_toptext.tick span {
    width: 290px;
    display: none;
    margin: 0 auto 20px;
    font-size: 0;
}
.mobile-payment-summary .grant-total-cls .cart_row .row-replace {
    display: flex;
    justify-content: space-between;
}

.order-divider {
    /* border-bottom: 1px solid #e5e5e5; */
    /* padding: 0 0 22px 0; */
    /* margin: 0px 0 22px 0; */
}

.login-links a{   margin-bottom: 20px; display: block}
.floating-round {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin: auto;
}

#open-promo-popup {
    padding: 32px 24px;
}
.promo-running{ padding: 0; list-style: none; margin: 30px 0 0 0; height: 300px; overflow-y: auto;}
.promo-running li{ width: 100%;background: #FFFFFF;
box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
border-radius: 16px 16px;margin: 0 0 16px 0;overflow: hidden;}
.promo-code-col-item {
    display: flex;
    justify-content: space-between;
    min-height: 106px;
}

.promo-code-col-image {
    width: 98px;
    position: relative;
}
.promo-code-earned-info {
    width: calc( 100% - 98px );
    padding: 12px 40px 12px 12px;
    position: relative;
}
.promo-code-earned-top{font-size: 14px;}
.promo-code-earned-top h6{ font-size: 16px; margin: 0 0 4px 0; line-height: 20px; }
.promo-code-earned-info button{ margin: 0; padding: 0; width: 24px; height: 24px; position: absolute; bottom: 12px; right: 12px; background: url(../images/plus-rounded.svg) no-repeat;font-size: 0; transition: 0.3S LINEAR ALL }
.promo-code-earned-info button:hover{ background: url(../images/plus-rounded.svg) no-repeat;     filter: grayscale(1); -webkit-filter: grayscale(1);}
.promo-code-col-image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.promo-code-earned-info .promo-description{ margin: 0 0 6px 0}
.promo-code-col-image:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    left: -10px;
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 16px;
    background-color: #fff;
    transform: translateY(-50%);
}
.promo-code-earned-info:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    right: -9px;
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 16px;
    background-color: #f8f8f8;
    transform: translateY(-50%);
}
.promo-popup-listing-page h3{
    letter-spacing: 0.006em;
    font-size: 24px;
}
#open-promo-popup .mfp-close{
    top: 41px
}
.chkout-main-header{margin: 0 auto; max-width: 1320px;padding: 30px 15px 0 15px; text-align: center;}

.tnk-chk-rhs .payment-via, .Viewreceipt .grant-total-cls .payment-via, .mobile-payment-summary .payment-via{margin-top: 8px;}
.tnk-chk-rhs .grant-total-cls {
    border-bottom: 1px solid #e5e5e5;
}
.mobile-order-item-title{display: none;}
.mobile-payment-summary .grant-total-cls {
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 24px 0;
}

.mobile-search-body {
    padding: 50px 24px 40px 24px;
}


.terms-list ul {
   list-style: none;
	 counter-reset: item;
}
.terms-list ul ul{
    padding-top: 10px;
}
.terms-list ul li {
	   counter-increment: item;
    margin-bottom: 8px;
	 }
.terms-list  ul li:before {
       margin-right: 5px;
       content: counters(item, ".") " ";
       display: inline-block;
     }
.terms-list ul li b{
    margin-bottom: 8px;
    display: inline-block
}

.ch-links ul li a .hm-dis {
    width: 24px;
    display: none;
}
.inn-product-img-bg {
    position: relative;
    max-height: 400px;
}
.inn-product-img-bg .banner-count-parent, .inn-product-img-bg .owl-nav {
display: none
}

.pop-whole-lhs-inner {
    text-align: center;
    padding-bottom: 30px;
}
.pop-whole-lhs-inner p{margin-bottom: 0}
.pop-whole-lhs-inner h3{margin-bottom: 5px;}

.login_pop_sub{ margin-bottom: 25px }
.login-links {
    text-align: center;
}
.alt_btns-alert .button{margin-bottom: 0}

.disable-text{
    pointer-events: none;
    opacity: 0.6;
}
span.selected_comboproducts_txt {
    font-weight: normal;
}
.outlet-listing-rhs > ul > li.no_restaurants, .search-resturent > ul > li.no_restaurants, .search-dishes > ul > li.no_restaurants {
    border: 0;
    padding: 0;
    width: 100%;
}
.outlet-listing-rhs > ul > li.no_restaurants h3 {

line-height: 36px;

}
.load_more_slots {
    margin: 15px 0 0 0;
}

.load_more_slots span {
    
    color: #1c1c40;
    cursor: pointer;
}
.load_more_slots span:hover{ color: #944FCA}
li.no-postal-list br {
    display: block;
    content: "";
    margin: 15px 0 0 0;
}
.self_outlet_inner li.no-postal-list a {
    padding: 0;
    color: #1c1c40;
    display: inline-block;
    
    border: 0;    
}
.mobile-menu-body .open-popup-link.full-width {
    margin: 20px 0 0 0;
}
.mobile-login-header .center-btn {
    margin: 30px 0 25px 0;
}
.innerproduct > h6 {
    margin-bottom: 30px;
    text-transform: capitalize;
    font-weight: bold;
}
.pro-remarks-sec h5 {
    margin-bottom: 15px; padding-left: 10px; font-size: 15px;
}

.toaser-alert {
    padding: 16px 12px;
    position: fixed;
    top: 30%;
    left: 0;
    background: #121212;
    box-shadow: 0px 6px 20px rgb(18 18 18 / 8%);
    border-radius: 4px;
    z-index: 99;
    color: #FFFFFF;
    text-align: center;
    max-width: 240px;
    margin: 0 auto;
    right: 0;
    font-size: 14px;
    line-height: 18px;
}
.set-oulet-txt a{
    color: #121212;
    
    font-size: 14px;
} 
.no-link-after .button{ text-transform: inherit; margin-bottom: 0; }
.open_cart_deatils{}
ul.order_ordstdtime_mainul li:last-child{
    border-bottom: 0;
}
.promo-popup-listing-page .focus-out {
    position: relative;
}
.checkout-apply-promo { 
    position: absolute;
    right: 20px;
    top: 10px;
    
    display: block;
    color: #f9a619
 }
.pro-description {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    margin: 0 0 15px 0;
    font-size: 14px;
}
.pro-description p{
    margin: 0;
}
.signup-popup, .forgot-password-popup, .login-popup, .guest-popup{
    max-width: 730px;
    box-shadow: 0 6px 20px rgb(18 18 18 / 8%);
    border-radius: 0;
        margin: 30px auto;
        position: relative;
    background: #f6f6f6;
    text-align: center;
}
.signup-popup label, .forgot-password-popup label, .login-popup label{text-align: left;  font-size: 14px; }
.signup-popup .full-login-new-body, .forgot-password-popup .full-login-new-body, .login-popup .full-login-new-body, .guest-popup .full-login-new-body{ max-width: 400px; }

.no-margin-btm{margin-bottom: 0;}
.controls.two-links {
    display: flex;
    flex-flow: column;
}
.two-links a {
    font-weight: 600;
    margin: 5px 15px;
    font-size: 16px;
    color: #000
}
.single-link a{
        
    font-size: 16px;
}
.two-links a:last-child{ margin-bottom: 0; }
.two-links a:first-child{ margin-top: 0; }
.full-login-new-header h3 {
    
    margin: 0 0 10px 0;
}
body .kep-login-facebook.metro {
    
    font-size: 15px;
    width: 100%;
    margin: 10px 0 20px 0;
    padding: 14px 10px;
    border-radius: 30px;
    background: #282728;font-family: 'Poppins', sans-serif; border-color: #282728; font-weight: 600; letter-spacing: 0.3px;
}
.kep-login-facebook.metro:hover{
    background:#3a5da2;
    color: #fff;
    border-color: #3a5da2
}
.or-seperator {
    padding: 15px 0 30px;
}
.form_checkbox_content {
    position: relative;
    padding-left: 35px;
    text-align: left;
    min-height: 24px;
    display: flex;
    align-items: center;
}

.form_checkbox_content input {
    position: absolute;
    left: 0;
}

.form_checkbox_content p {
    margin-bottom: 0;
}
.signup-popup .error, .login-popup .error, .forgot-password-popup .error{text-align: left;display: block;}
.popup-body h4 {
    margin: 0 0 20px 0;
    font-size: 20px;
}
.sign_reg a{  }
.mobile-login ul,
.desktop-login ul {
  padding: 0;
  list-style: none;
  position: absolute;
  top: 120%;
  left: 0;
  background: #fff;
  border-radius: 2px;
  min-width: 150px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  z-index: 5;
  display: block;
    box-shadow: 0 0px 5px rgb(0 0 0 / 15%)
}
.desktop-login ul {
  right: 0;
  left: inherit;
}
.mobile-login ul.active,
.desktop-login ul.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.mobile-login,
.desktop-login {
  position: relative;
}

.mobile-login ul li a,
.desktop-login ul li a {
  display: block;
  line-height: 1;
  padding: 10px 15px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
}
.mobile-login ul li a:hover,
.desktop-login ul li a:hover{
    color: #f9a619;
}
.desktop-login{ margin-left: 10px; }

.mobile-order-now {
   display: none;
    text-align: center;
}
.mobile-order-lhs{ width: 50%;background: #e9e9f1; display: flex; align-items: center; padding: 10px; position: relative; justify-content: center}
.mobile-order-lhs a{ color: #000;  padding: 0 30px 0 0; position: relative; text-transform: uppercase; font-weight: 500}
.mobile-order-lhs a:hover{ color: #000; }
.mobile-order-rhs{width: 50%; }
.mobile-order-lhs a:last-child:after {
    height: 14px;
    content: "";
    width: 2px;
    display: inline-flex;
    background: #000;
    position: absolute;
    left: -15px;
    top: 2px;
}
.mobile-order-lhs a:last-child{ padding-right: 0 }
.mobile-order-rhs a{   
    padding: 10px;
    display: block;
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    background: #3a5da1;
    color: #fff;  font-weight: 500;height: 100%;
}
	.menu_icon .icon-bar {display: block;background-color: #000;height: 2px;transition: all 400ms;}
	.menu_icon .icon-bar+.icon-bar {margin-top: 6px;}



.mbacc_mslidersec{ display: none; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px;}
.mobile-catgegory-nav .slick-track .slick-slide > div{ width: 100%;}    
.mobile-catgegory-nav .slick-track .slick-slide > div div{height: 100%;}    
    .mobile-catgegory-nav .slick-track .slick-slide > div div a{height: 100%;}
    .mobile-catgegory-nav{margin: 0 0 20px 0;display: block; border-bottom: 2px solid #13612d;}
.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a, .mobile-catgegory-nav .slick-track .slick-slide a{ display: flex; text-align: center; color: #000; font-size: 16px; padding: 12px 10px; background: #e4e4e4; text-transform: inherit; align-items: center; justify-content: center;line-height: 1; font-weight: 600;}
.mbacc_mslide.active a, .mobile-catering-slider .slick-track .active a, .mobile-catgegory-nav .slick-track .active a{ background: #1c1c40; color: #fff; }
.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow, .mobile-catgegory-nav .slick-arrow{ background: #8d8d8d; border: 0; width: 30px; height: 100%; position: absolute; left: 0px; top: 0; bottom: 0; margin: auto 0; text-align: center; overflow: hidden; font-size: 0;color: #fff; z-index: 1;padding: 0; min-width: inherit;border-radius: 0}
.mbacc_mslidersec .slick-next, .mobile-catgegory-nav .slick-next{ right: 0px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before, .mobile-catgegory-nav .slick-arrow:before{ content: "\f104"; display: inline-block; font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; line-height: 25px; font-size: 24px; }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before, .mobile-catgegory-nav .slick-next:before{ content: "\f105";}
.mobile-catgegory-nav .slick-track .slick-slide a{font-size: 15px; text-transform: capitalize;}    


.myacc_hea {position: relative; margin: 0 0 14px;}
.myacc_hea::after,
.myacc_pg::after {
    display: block; content: ""; clear: both;
}
.myacc_hea h3 {font-size: 27px; margin-bottom: 5px;}
.myacc_hea p {margin-bottom: 25px; color: #5b5b5b;}
.myacc_hea_inner {margin-top: 8px;}

.account_sec {display: -webkit-box; display: -ms-flexbox; display: flex;}
.accmenu_sec {background: #e8eaec; width: 265px; position: relative; color: #fff;}
.accprofile {padding: 26px 10px 0px;}
.accprofile_img {
    width: 140px; height: 140px; border: 2px solid rgba(0, 0, 0, 0.2); line-height: 134px; border-radius: 50%;
    margin: 0 auto 0; overflow: hidden;
}
.accprofile_img img {vertical-align: middle;}
.accprofile_info p {font-size: 15px; color: #000; font-weight: normal; margin: 0;}
.accpoints_sec {padding: 15px 7px 0px;}
.accpoint_tt span {background: #fff; padding: 0 4px; position: relative; display: inline-block;}
.accearn_points {background: rgba(0, 0, 0, 0.5); padding: 15px 15px 10px;}
.accearn_pointtop {position: relative; padding-left: 52px;}
.accearn_pointtop i {position: absolute; left: 0; top: 7px;}
.accearn_pointtop h4 {font-size: 15px; margin: 0; color: #fff;}
.accearn_pointtop span {font-size: 45px; display: inline-block; line-height: 0.8; color: #ffc800;}
.accearn_pointtop sub {font-size: 60%; bottom: 0;}
.accearn_points p {font-size: 13px; margin: 0;}
.accpoint_ec {position: absolute; right: 13px; bottom: 8px;}
.accsec_right {
    width: calc(100% - 0px); width: -webkit-calc(100% - 0px); display: inline-block; padding: 0px 10px;
}
.accsec_right h1 {font-size: 28px; color: #040507; margin-bottom: 15px;}
.accsec_right .welcome-title h1 {line-height: 1; display: flex; justify-content: left; align-items: center; margin:0px 0px 30px; 
     }

.accsec_right p {color: #000000; font-size: 17px; text-align: left;}
.acc-inform .form-group {margin-bottom: 10px;}
.other-add-body {
    height: 175px; border: 1px solid #f1efef; border-radius: 7px; padding: 16px 14px 0px 14px; background: #fff;
}
.other-add-row {
    display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;
    -webkit-box-align: center; -ms-flex-align: center; align-items: center; list-style: none;
    width: 100%; padding-left: 0; margin-bottom: 0;
}
.other-add-row li {
    width: 32.1875%; margin-right: 1.6666666666666666%; position: relative; margin-bottom: 10px;
}
.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}
.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}
.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.other-add-row span:hover {opacity: 0.5;}
.setting_menu_list {position: absolute; left: 0; right: 0; bottom: 0;}
.setting_menu_list > ul {list-style: none; padding: 0; margin-bottom: 0;}
.setting_menu_list > ul li {position: relative;}
.setting_menu_list > ul > li > a {
    padding: 12px 10px 12px 20px;
    background: #e8eaec;
    color: #151515;
    display: block;
    text-transform: uppercase;
    border-bottom: 2px solid #fff;
    margin-bottom: 0;
    
}
.setting_menu_list > ul li a:hover {
    background: #6e1d24;
    color: #fff;
}
.setting_menu_list > ul li:last-child a {
    border-bottom: 0;
}
.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}
.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}
.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}
.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}
.myacc_dropmenu li a {color: #fff;}
.myacc_dropmenu .tg-list {position: absolute; top: 0; right: -12px; padding-top: 0;}
.main_tabsec_inner> .nav-tabs>li {width: 33.17%; margin-right: .241545%; position: relative;}
.main_tabsec_inner> .nav-tabs>li:last-child {margin-right: 0;}
.main_tabsec_inner> .nav-tabs {text-align: center;}
.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}
.main_tabsec_inner> .nav-tabs>li.active>a {border: 0; background: #0b0405; color: #fff;}
.main_tabsec_inner> .nav-tabs>li> a > span > b {font-weight: normal;}

.count_num_t {
    background: #a7a7a7;
    width: 70px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #0b0405;
    font-size: 20px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
    border-bottom: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 5px solid #f0f0f0;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight:bold;
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight:bold;
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-weight:bold;
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}
.cur-order-del li a.btn:hover {
    color: #fff;
}
.cur-order-del li span {
    padding-left: 5px;
}
.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}
.calender-icon {
    background-position: -215px -258px;
}
.time-icon {
    background-position: -249px -257px;
}
.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}
.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.cur-ord-rec li a {
    font-size: 14px; text-transform: uppercase; text-decoration: underline; color: #2e2e2c;
}
.cur-ord-rec li a:hover {color: #909090;}
.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}
.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}
.order-cat-hea {background: #fff; padding: 8px 35px; max-width: 331px; margin-bottom: 10px;}
.order-cat h2 {font-size: 20px; color: #0b0405; margin-bottom: 0;}
.order-cat-table {padding: 0 35px;}
.order-cat-table h4 {color: #474747; font-size: 16px; font-weight: 700; margin-bottom: 11px;}
.order-cat-table table {border: 0; max-width: 364px; margin-bottom: 10px;}
.order-cat-table table td {font-weight: 300; font-size: 16px; color: #3d3d3d; padding: 0 5px;}
.order-cat-table table td.p_name {padding-left: 0;}
.p_name {width: 247px;}
.p_val {width: 80px;}
.ord-amount {padding: 10px 0 0 35px; border-top: 1px solid #efefee;}
.ord-amount .row {margin-right: -20px;}
.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}
.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}
.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}
.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}
.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}
.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}
.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}
.congrats::after {display: block; content: ""; clear: both;}
.congrats-inner, .promo-form {margin: 0px auto 40px; text-align: center; padding: 40px 15px; background:#1c1c40;}
.congrats-inner h3 {font-size: 17px; color: #fff; margin-bottom: 5px; letter-spacing: 0.5px; text-transform: capitalize;}
.congrats-inner h2 {    font-size: 40px;  color: #fff; margin-bottom: 0; }

.rewards-tab-section .ord-amount {border-top: inherit; padding-top: 0;}
.rewards-tab-section .order-cat-hea {margin-bottom: 7px;}
.earned_points {max-width: 300px; padding-left: 0; list-style: none; float: right; padding-right: 24px;}
.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}
.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.redeem_popsec {
    max-width: 420px;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 50px auto;
    border-radius: 22px;
}
.redeem_popsec .promo-earned-content {
    width: 75%; padding: 50px 10px 40px;
}

.redeem_bansec {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.redeem_popsec .redeem_bansec .promo-popup-image {
    width: 100%;
    margin: 0 auto;
}
.redeem_popsec .redeem_bansec .promo-earned-content {width: 100%; padding: 30px 30px 30px; text-align: center;margin: 0 auto; }
.redeem_popsec .redeem_bansec .promo-popup-image img { height: 100%; width: 100%; object-fit: cover; object-position: center;}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {width: 25%; height: 270px;}
.redeem_popsec .promo-earned-content h4 {font-size: 28px;max-height: inherit;margin-bottom: 15px}
.redeem_popsec .promo-earned-content h2 {font-size: 28px; color: #3c3c3c;}
.redeem_popsec .promo-earned-content p {margin: 0 0 15px;}
.redeem_popsec .abc_code {padding: 20px 0 0px;}
.redeem_popsec .promo-earned-content h2 span sup {font-size: 26px;}
.redeem_pop_image img {width: 100%; -o-object-fit: cover; object-fit: cover;}
.redeem_popsec .pouup_in {padding: 30px 55px 30px;}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}
.redeem_popsec .btn {
    color: #fff;
    display: block;
    max-width: 220px;
    border-radius: 6px;
    margin: 10px auto 0 auto; 
    background: #3a5da2;
    border-radius: 30px;
    padding: 10px;
    text-transform: uppercase;
}
.redeem_popsec .btn:hover {
    background: #000;
    color: #fff;
}
.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius: 7px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 7px;
    right: 7px;
}
.redeem_popsec .mfp-close:after {
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}
.redeem_popsec .mfp-close {
    background: #000 !important;
    width: 24px !important;
    height: 24px !important;
    opacity: 1 !important;
    border-radius: 6px !important;
}
.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}
.other-add {
    margin: 50px 0 0 0
}
.vh {
    visibility: hidden;
}
.myaccount_update.button {
    margin: 0;
}
.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
}
.form_grouptt{ font-size: 18px; }
.react-datepicker__header__dropdown{ text-align: left }
body .mainacc_menusec .react-datepicker__header{ background: #f7f7f7; }
body .mainacc_menusec .react-datepicker__day-name, body .mainacc_menusec .react-datepicker__day, body .mainacc_menusec  .react-datepicker__time-name{font-size: 13px}
body .mainacc_menusec .react-datepicker__day-names .react-datepicker__day-name{   }
.applypoints_cls{ max-width: 340px }
.applypoints_cls .applyPoints {
    margin: 0;
    padding: 12px 24px;
    font-size: 13px;
}
.restaurant-social, .restaurant-mobile-social {
    border-top: 2px solid #eaeaea;
    padding-top: 15px;
    display: flex;
    align-items: center;
}
.restaurant-mobile-social {
    display: none;
}
.restaurant-social span, .restaurant-mobile-social span {
    
    color: #888888;
}
.restaurant-social ul, .restaurant-mobile-social ul {
    padding: 0 0 0 20px;
    list-style: none;
    display: flex;
    align-items: center;
}
.restaurant-social ul li, .restaurant-mobile-social ul li {
    margin: 0 10px 0 0;
    font-size: 30px;
}
.restaurant-social ul li a, .restaurant-mobile-social ul li a {
    transition: 0.3s linear all;
 } 
.restaurant-social ul li a:hover, .restaurant-mobile-social ul li a:hover {
   opacity: 0.6;
}



.home-option ul{padding:0; margin: 0; list-style: none; display: flex; justify-content: center; flex-wrap: wrap}
.home-option li{margin: 0 2% 20px 0; position: relative; width: 32%;}
.home-option ul li:nth-child(3) { margin-right: 0; }
.home-option li:nth-child(3) a img{ height: 45px; width: 45px; }
.home-option li:nth-child(2) a img{ height: 40px; width: 44px; }
.home-option li:nth-child(1) a img{ height: 45px; width: 47px; }
.home-option li a{ text-transform: capitalize; padding: 18px 10px; font-weight: bold; font-size: 28px;border: 2px solid #3a5da2;transition: 0.2s linear all; text-align: center; box-shadow: 0 4px 10px 0 rgba(2,2,2, 0.11); display: flex; justify-content: center; align-items: center;height: 100%;  line-height: 1; background: #fff; border-radius: 40px;     flex-wrap: wrap;}
.home-option li a:hover, .home-option li.active a{ background: #3a5da2; color: #fff; border-color:#3a5da2;}
.home-option li a img{margin-right: 12px;}
.home-option li a .hoverstate{display: none;}
.home-option li a:hover .normalstate, .home-option li.active a .normalstate{display: none;}
.home-option li a:hover .hoverstate, .home-option li.active a .hoverstate{display: block;}


.home-outlet-listing ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.home-outlet-listing ul li {
    width: 50%;
    border: 8px solid #fff0;

}
.home-outlet-listing ul li a{
        display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.hol-img {
    width: 50%;
    overflow: hidden;
    padding-top: 40%;
    position: relative;
}

.hol-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.hol-desc {
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
}
.hol-desc h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 12px 0;
}
.outlet-tag span {
    background: #ff3334;
    color: #fff;
    font-size: 13px;
    border-radius: 15px;
    padding: 2px 10px;
    margin: 0 2px 3px 2px;
    display: block;
}

.outlet-tag {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.hol-desc p {
    font-size: 14px;
    margin: 0;
    padding: 10px 0 0 0;
    color: #000;
}
.color1 .hol-desc{ background: #fee986 }
.color2 .hol-desc{ background: #97e19e }
.color3 .hol-desc{ background: #f2d3cc }
.color4 .hol-desc{ background: #9dd0f8 }
.color5 .hol-desc{ background: #f3e3d4 }
.color6 .hol-desc{ background: #ffffff }
.hol-desc img{ margin-bottom: 10px }
.home-outlet-listing ul li:nth-child(1) .hol-img, .home-outlet-listing ul li:nth-child(5) .hol-img{ order: 2  }
.home-outlet-listing ul li:nth-child(1) .hol-desc, .home-outlet-listing ul li:nth-child(5) .hol-desc{ order: 1 }

.home-outlet-listing ul li:nth-child(4) .hol-img{ order: 2  }
.home-outlet-listing ul li:nth-child(4) .hol-desc{ order: 1 }
.hol-header {
    text-align: center;
    padding: 0 0 10px 0;
}
.hol-header h2{
    font-size: 40px;
    font-weight: bold;
    margin: 0 0 15px 0;
}
.hol-header p{margin: 0;}
.home-outlet-listing{
    position: relative;
    padding: 80px 0 160px 0;
    margin: 40px 0 0 0;
    z-index: 1;
}
.home-outlet-listing:after{
    content: "";
    position: absolute;
    top: 0;
    background: url(../images/test.png) no-repeat;
    background-size: cover;
    width: 100%;
    left: 0;
        height: 100%;
    z-index: -1;
}



.full-login-new-header{ background: #3a5da2; padding: 20px 30px; text-align: center;box-shadow:0px 4px 18px 0px rgba(2, 2, 2, 0.04); color: #fff;}
.full-login-new-header h3 {
    font-size:38px;
    margin-bottom: 0px;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.full-login-new-header p {
    margin-bottom: 0;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
    padding-top: 5px
}
.full-login-new-body {
    max-width: 610px;
    margin: 0 auto;
    width: 100%;
    padding: 30px 0 50px;
    text-align: center;
}
.two-button-row {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.two-button-row>div {
    width: 48%;
}
.two-button-row>div .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 13px 10px;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    font-size: 14px;
    height: 40px;
}
.two-button-row > div .button-left{ border: 1px solid #000; background: #fff; color: #000 }
.innervmid_in {
    max-width: 380px;
    margin: 0 auto;
}
.delevery_popup, .error_postal_popup{max-width: 740px; background: #f6f6f6;}
.offline_popup{max-width: 520px; background: #f6f6f6;    border-radius: 15px;}
.offline_popup img.offline-img{max-width: 160px;height: 135px;margin-bottom: 20px;}
.offline-head h2{font-weight: bold;color: #060503;text-transform: capitalize;margin: 0px 0px 20px 0px;}
.offline-head p{font-weight: 500;color: #241e21}
.offline_popup .innervmid_in{ max-width: 480px;}
.self_popup, .delevery_popup_datetime{ max-width: 780px; background: #f6f6f6; }
.self_popup .full-login-new-body{ max-width: 100%; padding: 30px 50px }
.delevery_popup_datetime .full-login-new-body{ max-width: 440px; padding:40px 20px }

.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
}

.self_popup_hea_row {
    padding: 20px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 {
    color: #fff;
}

.order-body .self_popup_hea_row p {
    margin-bottom: 0;
    font-size: 20px;
    color: #fff;
}

.self_popup_hea_col_left {
    padding-right: 15px;
}
.self_popup_hea_col_left img{width: 50px }

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}

.loc_icon {
    width: 33px;
    height: 33px;
    /*background: url(../images/location_btn.png) no-repeat;*/
    margin-right: 10px;
}

/* .self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
} */

.self_outlet h2 {
    color: #000000;
    font-size: 17px;
    text-align: left;
    margin: 0px 0 10px 5px;
    font-weight: 700
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height:500px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%;
    padding-right: 40px
}

/* .self_outlet .button {
    margin: 0;
    width: 100%;
    display: block;
} */

.self_pop_locbx {
    margin: 0 0 20px 0
}
.self_pop_locbx .focus-out:after{
    content:"\f002";
    font-family: FontAwesome;
    position: absolute;
    right: 20px;
    top: 13px;
    font-size: 22px;
    line-height: 1;
    color: #333;
}

.self_outlet .self_outlet_inner > li > span{ display: flex; background: #fff;border-radius: 6px; padding: 10px;box-shadow:0px 5px 13px 0px rgba(0, 0, 0, 0.03); text-align: left; overflow: hidden; color: #000; justify-content: space-between; transition: 0.2s linear all;cursor: pointer}

.outlet-list-lhs strong{ display: block; font-style: italic; color: #000; font-weight: 600; font-size: 17px; }
.outlet-list-name{ font-size: 14px; text-transform: inherit; font-weight: 600; color: #181818;
 font-family: 'Poppins', sans-serif;
    margin: 0 0 5px 0;}
.outlet-list-lhs{ width: 130px }
.outlet-list-rhs {
    color: #181818;
    display: flex;
    flex-flow: column wrap;
    justify-content:center;
    width: calc( 100% - 145px ) 
}
.outlet-list-rhs ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}
.outlet-list-rhs ul li{ margin: 5px 15px 0px 0; font-size: 13px; }
.outlet-list-rhs ul li:last-child{ margin: 5px 0 0 30px }
.outlet-list-lhs > img{width: 130px; max-height: 85px; object-fit: cover;}
.outlet-list-address{ font-size: 13px;margin-bottom: 5px; }
.otulet-timing{ font-weight: 600; font-style: italic;}
.outlet-list-rhs ul li.otulet-waiting span{ position: relative; padding: 0 0 0 24px;}
.outlet-list-rhs ul li.otulet-waiting span:before{ width: 16px; height: 16px; content: ""; position: absolute; left: 0; top: 0; background: url(../images/waiting-clock.png) no-repeat;}
.outlet-list-rhs ul li.otulet-direction span, .outlet-list-rhs ul li.otulet-location span{ 
    position: relative; padding: 0 0 0 25px;}
.outlet-list-rhs ul li.otulet-direction span:before{ width: 17px; height: 17px; content: ""; position: absolute; left: 0; top: 0; background: url(../images/compass.png) no-repeat; background-size: contain}

.outlet-list-rhs ul li.otulet-location span:before{ width: 17px; height: 17px; content: ""; position: absolute; left: 0; top: 0; background: url(../images/away-map.png) no-repeat; background-size: contain}

.otulet-direction a {
    border-bottom: 1px solid #8c8c8c;
    color: #000000;
}
.self_outlet .self_outlet_inner > li:hover > span, .self_outlet .self_outlet_inner > li.active > span{ background: #181818; color: #fff; }
.self_outlet .self_outlet_inner > li:hover .outlet-list-name, .self_outlet .self_outlet_inner > li:hover .outlet-list-rhs, .self_outlet .self_outlet_inner > li.active .outlet-list-name, .self_outlet .self_outlet_inner > li.active .outlet-list-rhs{ color: #fff;  }
.self_outlet .self_outlet_inner > li:hover .otulet-direction a, .self_outlet .self_outlet_inner > li.active .otulet-direction a{ color: #fff; border-color: #fff; }
.self_outlet .self_outlet_inner > li:hover .otulet-direction span:before, .self_outlet .self_outlet_inner > li.active .otulet-direction span:before{ background: url(../images/compass-light.png) no-repeat; background-size: contain; }
.self_outlet .self_outlet_inner > li:hover .otulet-waiting span:before, .self_outlet .self_outlet_inner > li.active .otulet-waiting span:before{ background: url(../images/waiting-clock-light.png) no-repeat; background-size: contain; }

.self_outlet .self_outlet_inner > li:hover .otulet-location span:before, .self_outlet .self_outlet_inner > li.active .otulet-location span:before{ background: url(../images/away-maphover.png) no-repeat; background-size: contain; }



.pop-whole-lhs-inner {
    text-align: center;
    max-width: 318px;
}

.pop-whole {
    position: relative
}

.login-popup .mfp-close, .signup-popup .mfp-close, .forgot-password-popup .mfp-close, .signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close {
    width: 24px;
    height: 24px;
    right: 10px;
    top: 10px;
    font-size: 0;
    margin: 0;
    opacity: 1;
    background: #000;
    border-radius: 3px;
}

.login-popup .mfp-close:hover, .signup-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover, .signup-corparatetwo-popup .mfp-close:hover, .guest-popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after, .signup-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after, .signup-corparatetwo-popup .mfp-close:after, .guest-popup .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}


.self_pop_item.dinein_popup_item {
    max-width: 312px;
    margin: 0 auto; text-align: center;}

.scan-qr{background: #fff; border: 2px solid #d1daec;padding: 40px 10px 30px;width: 100%;}
.scan-qr:hover{  border: 2px solid #3a5da2;background: #fff;}
.scan-qr span{display: block; margin: 30px 0 0 0; text-transform: capitalize; letter-spacing: 0; color: #000; font-size: 15px;}
.dinein_popup_item .takelist-btn-part .button {
    width: 184px;
    background: #000;
    color: #fff;
    margin: 0 auto;
}
.dinein_popup_item .form-group input[type="text"]{ border: 1px solid #cbcbcb; height: 40px; text-align: center; font-weight: 600;color: #000}
#dinein-popup .self_pop_row{ padding-top: 0}
.restaurant-outlet-info {
    background: #eef4ff;
    padding: 15px 0;
}
.restaurant-outlet-slider{padding: 15px 0;}
.restaurant-outlet-list{ box-shadow: 0px 3px 21px 0px rgba(0,0,0,0.10);    background: #fff;     position: relative; }
a.float-logo {
    position: relative;
    margin-top: -180px;
    display: block;
    margin-bottom: 20px;
}
.delivery-cart-div .col-sm-cls, .cart-header-second .col-sm-cls{
    width: 49%;
}

.delivery-cart-div, .cart-header-second {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.delivery-cart-div .col-sm-cls h4, .cart-header-second h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 10px 0;
}
.delivery-cart-div .col-sm-cls p {
    margin: 0 0 3px 0;
}
.hcart_add_innr_loca {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 0 0 0;
}

.hcart_add_innr_loca .input_field {
    max-width: 105px;
}

.hcart_add_innr_loca * {
    margin-left: 10px;
}
.cart-loc-change {
    color: #000;
    border-bottom: 1px solid #000;
    line-height: 1;
    display: inline-block;
    vertical-align: top;
}
.cart-header-second .col-sm-cls .form-control{ max-width: 180px}
.cart-header-second h4{margin-bottom: 5px;}

.product_orders_top {
    position: relative;
    display: flex;
    justify-content: flex-end;
    /* background: white; */
    padding: 16px 0px;
    line-height: 1;
}

.product_orders_top h4{font-size: 15px;color: #fff; margin: 0; font-weight: normal; text-transform: uppercase}
.product_orders_top a{  
    /* text-transform: uppercase; */
    text-decoration: underline;
    font-weight: 600;
    border-bottom: 1px solid #fff;
    color: #576AAB;
    font-size: 14px;
}
.inn_product_hea_left h3{ font-size: 20px; font-weight: bold;    margin: 0px 0px 10px 0px;}
.restaurant-outlet-info .bluebar-parent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.bluebar-middle{width: calc( (100% - 26%) - 26%) }
.bluebar-middle ul {
    display: flex;
    list-style: none;
    padding: 0;
    align-items: center;justify-content: center;
}
.bluebar-middle ul li{margin: 0 8px 0 0;font-weight: 500;}
.bluebar-middle ul li input{
  width: 124px; text-align: center; 
}
.bluebar-parent input{ border-radius: 40px;margin: 0;}
.bluebar-lhs{ width: 26%;  }
.bluebar-rhs{ width: 26%; }
.bluebar-middle ul li i{ color: #979797; margin: 0 10px 0 5px; }
.bluebar-middle ul li strong{ font-weight: bold;}
.popup-logo-button .button {
    width: 100%;
    font-size: 15px;
}

.button.btnnow {
    margin-bottom: 5px;
}

.button.regmem {
    margin-top: 0;
    border: 1px solid #cbcbcb;
    background: #fff;
    color: #3a5da2;
}
.button.btnguest{     border: 1px solid #cbcbcb;
    background: #fff;
    color: #1d1d1d;font-weight: 500; margin-bottom: 0 }

.popup-logo-button span {
    display: block;
    position: relative;
    text-transform: uppercase;
    color: #868686;
    font-weight: 500;
    z-index: 1;
}

.popup-logo-button span:after {
    height: 2px;
    background: #e1e1e1;
    width: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    z-index: -1;
}

.popup-logo-button span strong {
    background: #f6f6f6;
    display: inline-block;
    padding: 0px 10px;
    z-index: 3;
}
.popup-logo-body h4 {
    color: #5465a5;
    text-transform: uppercase;
    font-weight: bold;
    margin: 20px 0 5px 0;
    line-height: 1;
}
.popup-logo-body p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.button.regmem:hover, .button.btnguest:hover{
     background: #333;
    color: #fff;
}
#login-main-popup .full-login-new-body{ padding: 30px 10px; }
.banner-info {
    text-align: center;
    position: absolute;
    max-width: 850px;
    margin: 0 auto;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 15px;
}
.banner-info-inner h2 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
    text-shadow: -4px 1px 4px rgba(2, 2, 2, 0.29);    margin-bottom: 15px;
}

.banner-info-inner p {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
    text-shadow: -4px 1px 4px rgba(2, 2, 2, 0.29);    line-height: 1.2;
}



.takeaway-bag {
    width: 90px;
}

.takeaway-bag {
    -webkit-filter: invert(100%) sepia(100%) saturate(0) hue-rotate(288deg) brightness(102%) contrast(102%);
    filter: invert(100%) sepia(100%) saturate(0) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    transition: all .4s;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 6px;
    color: #bbbbbb;
    width: 100%;
    display: block;
}

.outlet-list-rhs .outlet-list-name{
    margin-left: 0px;
}

.self_outlet .button {
    margin: 0 5px 0;
    min-width: 164px;
}

.self_outlet .btn_empty, .takelist-btn-part.btn_sec .btn_empty{
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    border: 1px solid #000000;
    background: #fff;
    color: #000000;
}

.self_outlet .btn_fill{
    border: 1px solid #3a5da2;
}

.self_outlet .button:hover, .takelist-btn-part.btn_sec .btn_empty:hover, .two-button-row > div .button-left:hover{
    background: #000000;
    border: 1px solid #000000;
    color: #fff;
}

.delevery_popup_datetime .full-login-new-body h2{
    font-weight: 600;
    margin: 0;
}

.delevery_popup_datetime .full-login-new-body .awesome_del h2{
    margin-bottom: 25px;
}

.delevery_popup_datetime .btn_sec {
    margin: 10px auto 0;
    max-width: 100%;
}

.takelist-btn-part{
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}


.dinein_popup_item .takelist-btn-part .takeaway-btn-act{
    background: #3a5da2;
    border: 1px solid #3a5da2;
}

.dinein_popup_item .takelist-btn-part .takeaway-btn-act:hover{
    background: #000000;
    border: 1px solid #000000;
}

.self_outlet .self_outlet_inner>li.active>span, .self_outlet .self_outlet_inner>li:hover>span {
    border: 1px solid #3a5da2;
    background: #000;
}

.self_outlet .self_outlet_inner>li>span {
    border: 1px solid #ffffff;
}

.cdd-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom:40px;
}

.cdd-details-lhs, .cdd-details-rhs {
    width: 48%;
    position: relative;
    padding: 0;
    background: #fff;
}

.chkout-main-header h2{font-weight: 900; text-transform: capitalize; font-size: 38px; margin: 0 0 35px;}

.checkout-top-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #3a5da2;
    padding: 10px 15px;
    margin: 0 0 15px;
}

.checkout-top-header h2{
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    margin: 0;
}

.checkout-top-header h2 span{
    font-weight: 400;
}

.checkout-top-right .check_link{
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #fff;
}

.cart-header{
    display: flex;
    justify-content: center;
    background: #000;
    padding: 11px 15px;
}

.checkout-heading span{
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}

.checkout-inner-header{
    background: #000000;
    padding: 10px 15px;
}

.checkout-inner-header span{
    color: #fff;
    font-weight: 600;
}

.checkout-body-section{
    border: 1px solid #efefef;
}

.checkout-delivery-details{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.checkout-delivery-lhs, .checkout-delivery-rhs{
    width: 49%;
}

.checkout-delivery-lhs .chk_hea, .checkout-delivery-rhs .chk_hea, .checkout-pickup-group-top .chk_hea{
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
}

.checkout-delivery-lhs p, .checkout-delivery-rhs p{
    font-weight: 500;
    margin: 0;
    font-size: 14px;
}

.checkout-delivery-rhs p, .checkout-delivery-rhs .chk_hea{
    text-align: right;
}

.controls-three-small {
    align-items: center;
    margin: 10px 0;
}

.controls-three-small span {
    width: 20px;
    text-align: left;
}

.controls-three-small, .cthree-small-inner {
    display: flex;
    justify-content: space-between;
}

.cthree-small-inner{
    width: calc(100% - 20px);
}

.controls-three-small .focus-out {
    width: 48.5%;
}

.controls-three-small input[type=text] {
    height: 40px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 10px 5px;
}

.checkout-control-group-top a {
    float: right;
    font-size: 14px;
    color: #000;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0;
    display: inline-block;
    vertical-align: top;
    transition: all .3s linear;
}

.checkout-control-group-top a:hover{
    color: #3a5da2;
}

.delivery_info_tmain .syd_merge .syd_date {
    width: 30%;
}
.delivery_info_tmain .syd_merge .syd_time {
    width: 30%;
}

.d_datediv h5, .d_timediv h5{
    /* text-transform: uppercase; */
    margin: 0;
    font-size: 15px;
}

.d_datediv input, .d_timediv input{
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #D1D1D1;
    padding: 6px 5px;
}

.d_timediv, .d_timediv input{
    text-align: right;
}

.checkout-control-group-middle{
    padding: 0 20px 20px;
}

.checkout-billing-address {
    position: relative;
    margin: 20px 0;
}

.checkout-billing-address .chk_hea{
    padding: 10px 15px;
    margin: 0 0 10px;
    background: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: space-between;
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
    font-size: 15px;
}


.checkout-billing-address .chk_hea>span{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
    padding-right: 30px;
    color: #fff;
}

.checkout-billing-address .custom_checkbox {
    position: absolute;
    top: 8px;
    right: 5px;
}

.checkout-billing-address .custom_checkbox input{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
}

.check-billing {
    padding: 20px 0 0;
    border: 1px solid #D1D1D1;
    border-radius: 12px;
    padding: 30px 24px 25px 24px;
}

.check-billing .controls-three {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    max-width: 100%;
}

.check-billing .form-group.controls-three>div {
    width: 31.3333%;
}

.check-billing .form-group.controls-three .form-group{
    margin: 0;
}

.checkout-billing-address .chk_daddress{
    max-width: 100%;
}

.chk_daddress .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.product_orders_top h5{
    color: #fff;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
}

.ckout-lhs-bdy .cart-outlet-name{
    display: none;
}

.product-details.ckout-product-details  {
    padding: 15px 0;
    position: relative;
    border-bottom: 2px solid #e6e6e6;
}

.cart_img {
    width: 90px;
    margin: 0 10px 5px 0;
}

.chekout_cart_bar .cart_left .cart_info {
    width: calc(100% - 100px);
}

.ckout-product-details .row-replace .cart_left{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ckout-product-details .row-replace .cart_right {
    width: 125px;
    display: block;
}

.ckout-product-details .cart_price {
    position: relative;
    margin: 0 0 10px;
    padding-right: 22px;
    line-height: 1;
}

.ckout-product-details .cart_price p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.ckout-product-details .cart_remove:after, .ckout-product-details .cart_remove:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 1.9px;
    background-color: #fff;
}

.ckout-product-details .cart_remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ckout-product-details .cart_remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.ckout-product-details .cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 14px;
    right: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.ckout-product-details .cart_remove:hover{
    background: #000000;
}

.product-details.ckout-product-details:last-child{
    border-bottom: 0;
}

.checkout-right-body-section .cart_table {
    padding: 10px 15px 0;
}

.checkout-right-body-section{
    border: 1px solid #efefef;
}

.cdd-details-rhs .cart_body {
    padding: 0 15px
}

.redeem {
    border: 1px solid #060203;
    border-radius: 9px;
    padding: 17px 35px 17px 35px;
    position: relative;
    margin: 15px 0;
    background: #060203;
}

.redeem-item-top h4 {
    margin-bottom: 0;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
}

.redeem-item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.redeem-item-hea input{
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    padding: 6px 5px;
    background: transparent;
    color: #fff;
    margin-bottom: 0;
    padding-left: 0;
    font-size: 14px;
    padding-bottom: 0
}

.redeem_apply.applypromo_cls {
    width: 170px;
}

.redeem-item-hea {
    width: calc( 100% - 190px );
}

.redeem-item-top a {
    color: #fff;
    position: absolute;
    right: 0;
    top: 3px;
    font-size: 12px;
}

.redeem-item-top a b{
    color: #ebc512;
}

.applypromo_cls .applyPromo{
    margin:0; width: 100%;padding: 15px 10px;
}
.redeem-item-top {
    position: relative;
}
.checkout-right-body-main .cart_footer{
    border-radius: 9px;
    padding: 24px 24px;
    margin-top: 20px;
    background: #eef4ff;
}

.checkout-right-body-main .progress_bar_div {
    margin: 15px 0;
}

.chk-payment-row{
    border: 1px solid #D1D1D1;
    position: relative;
    margin-bottom: 16px;
}

.chk-payment-col{
    padding: 24px 40px 10px;
}

.chk-payment-title{
    background: #000000;
    padding: 10px 15px;
}

.chk-payment-title h3{
    color: #fff;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
}

.radio_con {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.radio_con span{
    font-weight: 600;
    padding-left: 5px;
}

.radio_con img{
    max-width: 156px;
}

.chk-payment-col-radio li:hover, .chk-payment-col-radio li.active{
    background: #e8eef8;
    border: 1px solid #e8eef8;
}

.chk-payment-btn {
    width: 100%;
    padding: 10px;
}

.check_out_fourcommon {
    padding: 0 0 100px;
}

.checkout-pickup-main{
    padding: 20px;
}

.checkout-pickup-group-top .chk_hea{
    text-align: center;
}

.checkout-pickup-group-top p{
    font-weight: 500;
    margin: 0;
    font-size: 14px;
    text-align: center;
}

.checkout-pickup-group-top a{
    text-align: center;
    font-size: 14px;
    color: #000;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0;
    display: inline-block;
    vertical-align: top;
    transition: all .3s linear;
}

.checkout-pickup-group-top a:hover{
    color: #3a5da2;
}

.pickup-group-link{
    text-align: center;
}

.checkout-pickup-group{
    padding: 0px 0 40px;
}

.datetime_selt_sec .syd_date, .datetime_selt_sec .syd_time{
    width: 48%;
}

.cart-date-right{
    display: flex;
    justify-content: flex-end;
}

.cart-prdlist h4{
    /* padding-left: 24px; */
}

/************** 26-10-22 SURESH CSS ******************/

.tnkyou-maindiv{
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 0 100px;
}

.tnkyou-header{
    text-align: center;
}

.tnkyou-header img{
    margin: 0 0 20px;
}

.tnkyou-header h2{
    font-weight: 700;
    font-size: 38px;
    text-transform: capitalize;
    color: #576AAB;
    margin:  0;
}

.tnkyou-innerdiv{
    border: 1px solid #e8eef8;
}

.tnk-detail h2{
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    background: #576AAB;
    color: #fff;
    font-size: 18px;
    padding: 12px 15px;
}

.tnk-order{
    padding: 15px 20px;
}

.tnk-order h3{ 
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize; 
    margin: 0 0 5px 0;
    color: #3a5da2;
}

.tnk-order p{
    margin: 0;
}

.pickup-cart-div .pickup-thankfull  h4{
    font-weight: 600;
    font-size: 16px;
}

.pickup-thankfull{
    padding: 0 20px;
}

.pickup-date-maindiv{
    padding: 20px 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.pickup_total_left, .pickup_total_right{
    width: 50%;
}

.pickup_total_left h2, .pickup_total_right h2{
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 10px;
    text-transform: uppercase;
}

.pickup_total_left h4, .pickup_total_right h4{
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.pickup_total_right{
    text-align: right;
}

.thank-order-detaildiv{
    background: #eef4ff;
    padding: 0 0 20px;
}

.tnkyou-product-inner {
    padding: 0 20px;
}

.tnkyou_item_bodydiv {
    /* padding: 10px 20px 20px; */
}

.tnkyou-bd-heading h5{
    background: #576AAB;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
    text-transform: uppercase;
    margin: 0 0 20px;
}

.tnkyou_row{
    position: relative;
    margin: 0 0 5px;
}

.tnkyou_row-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tnkyou_left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 130px);
    width: -webkit-calc(100% - 130px);
}

.tnkyou_left .cart_info{
    width: calc(100% - 115px);
}

.tnkyou-product-parent {
    padding-bottom: 15px;
}
.tnkyou-product-parent:last-child {
    border-bottom: 1px solid #d4d4d8;
}

.tnkyou_left .cart_info h4{
    font-weight: 600;
}

.tnkyou_right {
    width: 125px;
}

.tnkyou_right .cart_price p{
    font-weight: 600;
}

.tnkyou-innerdiv .cart_footer {
    padding: 20px;
    background: white;
    border-radius: 0;
}

.tnk_cart_footer .grant-total-cls{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tnk_cart_footer .grant-total-cls p, .tnk_cart_footer .grant-total-cls span{
    font-weight: 700;
    font-size: 22px;
}

.tnk-chk-order .button{
    display: block;
    margin: 20px 0;
}

.tnk-delivery .delivery-cart-div{
    flex-flow: column;
    padding: 20px 20px;
}

.tnkorder-first{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tnkorder-first .order-hlhs{
    width: 50%;
}

.tnkorder-first .order-hrhs{
    width: 50%;
}

.tnkorder-first .order-hlhs h5, .tnkorder-first .order-hrhs h5, .product-step-inner-white .delivery_total_left h2{
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.tnkorder-first .order-hlhs p, .tnkorder-first .order-hrhs p{
    font-size: 14px;
    margin: 0;
}

img.cart-direction-left {
    height: 25px;
}

.cart-direction-right{
    height: 20px;
}

.tnk-delivery .cart-direction::before {
    position: absolute;
    top: 15px;
    left: 50px;
    right: 30px;
    background: #fff;
    content: "";
    margin: auto;
    border: 1px dashed #000000;
}

.checkout-pickup-group-middle .timeslot_info_main .syd_date input,
.checkout-pickup-group-middle .timeslot_info_main .syd_time input{
    border: none;
    pointer-events: none;
    padding: 0;
    height: 30px;
}

.checkout-pickup-group-middle .timeslot_info_main .syd_date h5,
.checkout-pickup-group-middle .timeslot_info_main .syd_time h5{
font-weight: 700;
    font-size: 15px;
    /* text-transform: uppercase; */
    margin-bottom: 5px;
    line-height: 1;
}

.checkout-pickup-group-middle .timeslot_info_main .syd_date{
    width: 48%;
}

.checkout-pickup-group-middle .timeslot_info_main .syd_time input {
    text-align: right;
}

.unselect-cart-action .cartaction_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.acc-inform .form-group .row .col-md-6 {
    padding: 0;
}

.acc-inform .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.profile-info-div .form-group .row {
    margin-left: 0;
    margin-right: 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.css-vj8t7z{
    height: 40px;
}

.address-info-div .form-group .row {
    margin-left: 0;
    margin-right: 0;
}

.address_linfo {
    margin-bottom: 10px;
}

.address-list-main .address_linfo .address_chk {
        position: absolute;
    left: 0;
    top: 0;
}

.address_linfo {
    margin-bottom: 10px;
}

.address-list-cls.address-list-main{
    text-align: left;
    padding-bottom: 10px;
}

/************************* MY ORDER ******************************/

.myacc-thkyou-main{
    width: calc(100% - 380px);
    padding: 0;
    border: 1px solid #e8eef8;
}

.myacc-thkyou-main .tnk-header{
    text-align: center;
    padding: 30px 20px;
}

.myacc-thkyou-main .order-date-parent{
    padding: 40px 20px;
}

.myacc-thkyou-main .orderitem_body_div{
    border: 0;
    border-radius: 0;
}

.myacc-thkyou-main .hcart_tt-details{
    text-align: center;
    margin: 0 0 20px;
}

.myacc-thkyou-main .hcart_tt-details h5{
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    background: #000000;
    color: #fff;
    font-size: 18px;
    padding: 12px 15px;
}

.myacc-thkyou-main .cart_price p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.myacc-thkyou-main .cart_info h4 {
    font-size: 16px;
    margin: 0 0 2px;
    color: #121212;
}

.myacc-thkyou-main .tnk_cart_footer{
    background: #000000;
    text-align: center;
    border-radius: 0;
}

.myacc-thkyou-main .tnk_cart_footer h4{
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    background: #000000;
    color: #fff;
    font-size: 18px;
    padding: 12px 15px;
}

.tnk_footer_inner{
    padding: 24px 24px;
    background: #e8eef8;
    border-radius: 0;
}
.myacc-thkyou-main .Viewreceipt .tnk_cart_footer{
    background: #e8eef8;
    text-align: left;
    padding: 24px;
}

.myacc-thkyou-main .cart_row{
    width: 100%;
}

.order-smry-lhs p{
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.order-smry-rhs span{
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.myacc-thkyou-main .order-divider:last-child{
    border: 0;
}

.order-footer-add-info {
    padding: 40px 20px 20px;
}
.order-footer-add-info h4{ font-size: 18px; margin-bottom: 5px; }
.order-footer-add-info a:hover{ border-bottom: 1px solid #000; }
.delivery_total_left{ 
    /* padding-left: 37px; position: relative; */
}

.alt_btns a {
    margin: 0 5px;
}

.alt_btns a.button-left {
    background: #000000;
    color: #fff;
}

.warning_popup .alert_header{
    border-radius: 16px 16px 0 0;
}

.changepw_popup{
    border-radius: 16px;
}

.no-recrds-found {
    margin: 0;
    min-height: 270px;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addcart_done_maindiv .btn_black{
    margin: 0 0 0 5px;
}
.dinein-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;
}
.dinein-scantable a, .dinein-scantable p {

    margin-bottom: 0;
}
.dinein-scantable p {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dinein-scantable a {
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #3a5da2;
    /* position: absolute; */
    left: 50px;
    border-radius: 6px;
    letter-spacing: .5px;
    line-height: 1;
    white-space: nowrap;
    margin-top: 20px;
}
.dinein-heading h5 {
    margin-bottom: 0;    font-size: 17px;
}
.dinein-scantable a:hover {
    background: #000;
    color: #fff;
}
.current_order .myacc_order_details ul, .past-order .myacc_order_details ul  {
    max-width: 580px;
}
.timeslot_info {
    margin: 10px 0 10px;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    background: #ffbbbb;
}
.tnk_cart_footer .order-divider:last-child{ margin-bottom: 0; padding-bottom: 0 }
.tnk_cart_footer > h3{
    font-size: 20px;
}
.address-list-main .address_linfo {
    position: relative;
    min-height: 24px;
    padding-left: 34px;     display: flex;
    align-items: center;
}
.timeslot_info_main {
    width: 100%;
}

.timeslot_info_main .syd_date {
    width: 48%;
}

.timeslot_info_main .syd_time {
    width: 48%;
    text-align: right;
}
.product-step-inner-white {
    max-width: 680px;
    background: #fff;
    border-radius: 20px;
    margin: 0 auto;
    padding: 40px 10px;
}
.big-plane {
    max-width: 330px;
}
.payment-de {
    max-width: 330px;
}
.product-step-top{
    color: #000;
}
.product-step-top img{
    margin-bottom: 30px
}
.product-step-top h2{
  text-transform: inherit;
  font-size: 25px;    
  margin: 0 0 5px 0;   
    color: #000;
        font-weight: 600;
}
.product-step-top p{
  font-size: 17px;    
  margin: 0;    
    font-weight: 600;
}
.product-step-inner{
    max-width: 580px;
    margin: 0 auto;
}
.product-step-top {
    margin: 0 0 20px 0;
}
.product-step {
    padding: 0px 0 20px;
    margin: 0 0 30px 0;
}
.progressbar {
    padding: 0;
    display: flex;
}
.progressbar li {
    list-style-type: none;
    flex: 1 1 0;
    position: relative;
    text-align: center;
}
.progressbar li figure {
    width: 20px;
    height: 20px;
    display: block;
    text-align: center;
    margin: 0 auto 0;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: #dad8d8;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 0
}
.progressbar li:first-child figure{ left: 0px; right: inherit}
.progressbar li:last-child figure{ left: inherit; right: 0px}

.progress-content {
    padding: 50px 0 0;
}
.progressbar li:after {
    width: 100%;
    height: 6px;
    content: "";
    position: absolute;
    background-color: #e8e7e7;
    top: 8px;
    left: 0;
    z-index: 0;
    
}
.progressbar li:first-child:after {
    border-radius: 10px 0 0 10px
}
.progressbar li:last-child:after {
    border-radius: 0 10px 10px 0px
}

.progressbar li.active figure {
    background: #000;
}
.progressbar li.active:after{ background: #000;}

.pro-avbl-errormsg {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    transition: all .4s;
    margin-top: 5px;
    width: 100%;
}
.retry .button {
    padding: 12px 24px;
    margin: 10px 5px;
    font-size: 14px;
}

.retry .button:first-child {
    background: #000;
}

.buttonscart{
    margin: 0 10px;
}
.product-step-inner-white .delivery_total_number {
    padding: 10px 15px;
    margin: 0; box-shadow: none; background: none; border-radius: 0;  width: 100%; overflow: hidden;
}
.product-step-inner-white .delivery_total_left {
    float: left;
    text-align: left;
    width: 50%; padding: 0;   
}
.product-step-inner-white .delivery_total_left.delivery_total_right {
    float: right;
    text-align: right;
}
.order-rider-details { padding: 20px 20px 0; display: flex; flex-wrap: wrap; justify-content: space-between; }
.order-rider-details .delivery_total_left { padding-left: 0; }
input[type="text"]:read-only {
    background-color: transparent;
}
.order_delivery_item li label { color: #e56666; font-size: 15px; }
.order_delivery_item li a:hover label { color: #FFFFFF; }
.home-option li a label {display: block;clear: right;margin: 0;width: 100%;padding: 7px 0 0 0;color: #3a5da2; font-size: 18px;}
.home-option li a:hover label { color: #FFFFFF; }
.inn-product-img-bg > div > img {
    max-height: 400px;
    width: 100%;
    object-fit: cover;
}
.only-for-iphone { display: none; }
/* CSS specific to iOS devices only */
@supports (-webkit-overflow-scrolling: touch) {
    .prd_chosen_sub_row.only-for-iphone {
        display: block;
        position: sticky;
        top: 0;
        bottom: inherit;
        border: 0;
        padding: 10px 12px;
        background: #FFF;
    }
    .other-device-addtocart-btn { display: none; }
}

.mobile-nav {
    display: none;
}

.desktop-nav {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}

.view-more {
    position: relative;
    z-index: 10;
}

.view-more a {
    display: flex;
    align-items: center;
}

.view-more a .fa {
    margin-left: 5px;
}

.view-more__list {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 28px;
    right: 0;
    background: white;
    z-index: 100;
    padding: 10px 20px;
    border: 1px solid rgba(0,0,0,0.2);
}

.view-more__list li {
    margin-bottom: 10px;
    white-space: nowrap;
}

.cartaction_checkout.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.cartaction_checkout.disabled:hover {
    background: #3a5da2;
}

.working-info-parent__left {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.redeem {
    display: none;
}

.checkout-control-group-top {
    display: flex;
    justify-content: space-between;
}

.checkout-control-group-top .chk_hea {
    display: flex;
    flex-direction: column;
    /* width: 30%; */
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 15px;
}

.checkout-control-group-top .chk_hea:nth-child(2) {
    font-weight: 400;
    text-align: right;
}
.checkout-control-group-top .chk_hea:nth-child(2) span {
    font-weight: 700;
}

.checkout-control-group-top .chk_hea span {
    color: black;
    font-weight: 400;
    margin-left: 0;
    margin-top: 5px;
}

.continue-btn-row {
  margin: 15px 0;
  text-align: center;
}

.continue-btn-row a {
  margin: 0;
  font-weight: 600;
  text-transform: inherit;
  color: #576aab;
  font-size: 17px;
}

.continue-btn-row a:hover {
  color: #1c1c40;
}


.add_item {
    margin-right: 15px;
}

.product-menu-listing .add_item {
    display: none;
}

.back-to-menu {
    display: none;
}
.error_combo_div {
    color: #ff0000;
}



.htico_search > a,
.htico_search_mbl > a {
    position: relative;
    display: block;
}
.hsearch_sec, .hsearch_sec_mbl {
    width: 380px;
    max-width: 90vw;
    position: absolute;
    right:0px;
    top: 40px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    visibility: hidden;
}

.hsearch_sec.open, .hsearch_sec_mbl.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}
.hsearch_sec .search_result, .hsearch_sec_mbl .search_result_mbl, .product_search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none;
}
.product_search_result{position: absolute; top: 100%; left: 0;z-index: 5;width: 100%;}

.search_result li, .search_result_mbl li, .product_search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found, .search_result_mbl li a, .product_search_result li a {
    color: #333;
    display: block;
    padding: 9px 15px
}

.search_result li a:hover, .search_result_mbl li a:hover, .product_search_result li a:hover {
    background: #000;
    color: #fff;
}

.hsearch_sec.open .input-sec, .hsearch_sec_mbl.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input  {
    padding-right: 10px;
    margin-bottom: 0;
    border-color: rgba(0, 0, 0, 0.3);;
}
.htico_search{position: relative; margin: 0 0 0 15px; }
.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: none
}
.hsearch_trigger_mbl {
    display: none;
}